import React, { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  Input,
  Divider,
  Textarea
} from '@chakra-ui/react'


const stripHtmlTags = (html) => {
  return html
    .replace(/<br\s*\/?>/g, '\n')
    .replace(/<[^>]*>/g, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&bsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'");
}

const convertToHtmlFormat = (text) => {
  let formattedText = text
    .split('\n')
    .map(line => line.trim())
    .filter(line => line.length > 0)
    .join('<br>');
  formattedText = formattedText.replace(/\((.*?)\)/g, '<strong>($1)</strong>');
  formattedText = formattedText.replace(/\{(.*?)\}/g, '<strong>{$1}</strong>');
  return formattedText;
};
 
const EditTemplateModel = ({ isOpen, onClose, templateId, name, body }) => {
  const [plainTextBody, setPlainTextBody] = useState(stripHtmlTags(body));
  const [currentName, setCurrentName] = useState(name);
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");

  const handleChange = (event) => {
    setPlainTextBody(event.target.value);
  };

  const handleNameChange = (event) => {
    setCurrentName(event.target.value);
  };

  const handleSave = async () => {
    const formattedBody = convertToHtmlFormat(plainTextBody);
    console.log(formattedBody)
    // onClose();

    try {
      const response = await fetch(BASE_URL + "/get-autorespond-template/", {
        method: "PATCH",
        body: JSON.stringify({
          temp_id: templateId,
          body: formattedBody,
          name: currentName
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log(data, "data is here")
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent style={{ maxWidth: '700px', height: '500px' }}>
          <ModalHeader>Edit Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Flex>
              <Box>Name</Box>
              <Input type='text' value={currentName} onChange={handleNameChange}></Input>
            </Flex>
            <Divider />
            <Box mt={4}>
              <Textarea value={plainTextBody} width="100%" height="250px" onChange={handleChange} />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={handleSave}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditTemplateModel

