import { Avatar, Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Input, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text, useColorMode,  useToast } from '@chakra-ui/react'
import React, { useState,useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { PiPaperPlaneRightLight } from 'react-icons/pi';
import { IoMdNotifications } from "react-icons/io";
import { FaCalendarAlt } from "react-icons/fa";
import EventsCalendar from '../calendar/EventsCalendar';
import { useNavigate } from 'react-router-dom';
import "./TopPannel.css"
import { CloseButton } from '@chakra-ui/react'
import { IoIosArrowUp } from "react-icons/io";
import NotificationPopModal from '../Modal/NotificationPopModal';
import { useSelector } from 'react-redux';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
  } from '@chakra-ui/react'
  import axios from 'axios';

const TopPanel = () => {
    const navigate = useNavigate()
       const[counts,setCounts]=useState('')
    const Email = localStorage.getItem("userEmail");
    // const userImg = localStorage.getItem("userImg");

    const userImg = useSelector((state) => state.userImg) || localStorage.getItem('userImg');

    const [isCalendar,setIsCalendar] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const[notificationsData,setNotificationsData]=useState([])
    
    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
    const token = localStorage.getItem("token");

    const location = useLocation() ;
    const path = location.pathname ;
    const { colorMode, toggleColorMode } = useColorMode();
 
  
    const segments = [path.split('/').filter(Boolean).slice(1)[0]];
    // console.log('vv path:', path);
    // console.log("vv segments>>>",segments)
  
    //  if(segments === undefined){
    //     navigate('/login')
    //  }

  const closeCalendar = ()=>{
    setIsCalendar(false)
  }


    const handleLogout =()=>{
        
        localStorage.removeItem('userEmail')
        localStorage.removeItem('token')
        localStorage.removeItem('Role')
        window.location.href = '/login'
      
        
    }

    const handleNotifications=(async()=>{


       
            // console.log("id from api",ID);
             setIsLoading(true)
             try {
           const result = await axios.get(`${BASE_URL}/get-notification/`, {
             headers: {
               Authorization: `Bearer ${token}`
             }
           })
           
           if (result.status === 200) {
             setIsLoading(false)
             setNotificationsData(result.data.data)
             setCounts(result.data.count)
           }
         } catch (error) {
           console.log("error>>>", error)
           setIsLoading(false)
         }
       
    })

    useEffect(()=>{
        handleNotifications()
    },[counts])

    const formatNotificationtype=((type)=>{
return <Link  style={{ color: 'steelblue' }} >{type}</Link>
    })

const handleCross = async (id) => {
      
        // setIsLoading(true);
        try {
          const result = await axios.post(
            `${BASE_URL}/notifications-read/`,
            {
              notification_id: id,  // Sending notification_id in the body
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
      
          if (result.status === 200) {
            handleNotifications()
            // setIsLoading(false);
            // console.log("resultssssss>>>", result.data.data);
            // setNotificationsData(result.data.data);
            // setCounts(result.data.count);
          }
        } catch (error) {
          console.log("error>>>", error);
          setIsLoading(false);
        }
      };
      
  
    return (
        <Box p={"10px 20px"} h={"60px"} boxShadow={colorMode === 'light' ? "inset 0px -5px 5px -5px rgba(0, 0, 0, 0.1)" : "inset 0px -5px 5px -5px rgba(255, 255, 255, 0.1)"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Breadcrumb separator={<Box mx={1}><PiPaperPlaneRightLight color='gray.500' /></Box>}>
                    {
                        segments.map((segment, index) => (
                            <BreadcrumbItem key={index} isCurrentPage={index === segments.length - 1} >
                                <BreadcrumbLink href={`/admin/${segments.slice(0, index + 1).join('/')}`} _hover={{ textDecoration: "none" }}>
                                     <Text fontWeight={"500"} fontSize={"2xl"} color={colorMode === 'light' ? "gray.600" : "gray.400"}>
                                        {
                                           segment && segment?.includes('_') ?
                                            segment?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 
                                            segment?.includes('-') ?
                                            segment?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '):
                                            segment?.charAt(0).toUpperCase() + segment?.slice(1).replace(/%20/g, ' ')
                                        }
                                    </Text>
                                </BreadcrumbLink>
                            </BreadcrumbItem>
                        ))
                    }
                </Breadcrumb>

                <Flex alignItems={"center"} gap={"20px"}>
                    {/* <Box onClick={toggleColorMode} _hover={{ borderRadius: "100vh", bg: colorMode === 'light' ? "#EDF2F7" : "#2C313D", cursor: "pointer" }} p={"8px"}>
                        {colorMode === 'light' ? <MdDarkMode size={20} color='#A0AEC0' /> : <MdLightMode size={20} color='#A0AEC0' />}
                    </Box> */}

                    <Menu>
                        <div className='Calender-main-cursor'>
                        <FaCalendarAlt className='cal_color' size={25} onClick={()=>setIsCalendar(true)}/>
                        </div>
                        <div className='notification-numbericon'>
                            <div className='noti-main'>
                        <Popover >

                        <IoMdNotifications size={28} onClick={handleNotifications} />
                        <PopoverContent className='noti-icon'>
    <PopoverArrow className='arrow'/>
    <div className='noti-arrow'>
    {/* <IoIosArrowUp /> */}
    </div>
    <PopoverCloseButton />
    <PopoverHeader className='noti-header'>Notifications</PopoverHeader>
    <PopoverBody className='noti-body'> {notificationsData?.map((notification)=>{
        return (
            <div key={notification.notification_id} className='noti-item'>
             <CloseButton  onClick={() => handleCross(notification.notification_id)} />    <Text>{ formatNotificationtype( `${notification.notification_type}!`)} {notification.message}</Text>
                
            </div>
        )
    })}
    </PopoverBody>
  </PopoverContent>
                        </Popover>
                        </div>


                        <span className='number-icon'>{counts}</span>
                        
                        </div>
                        
                        <MenuButton>
                       
                            <Avatar size={"sm"} name={Email} src={userImg == null ? "http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png" : userImg} color={"#ffffff"} boxShadow={"0px 0px 6px #A0AEC0"} />
                        </MenuButton>
                        <MenuList mt={"6px"}>
                            <MenuGroup title={Email}>
                                <MenuDivider />
                                <Link to="/login">
                                    <MenuItem icon={<RiLogoutCircleLine size={18} />} onClick={handleLogout}>Logout
                                    </MenuItem>
                                </Link>
                            </MenuGroup>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
            {isCalendar && <div className='big-calendar-container'>
            <EventsCalendar closeCalendar={closeCalendar}/>
            </div>}
        </Box>
    )
}

export default TopPanel