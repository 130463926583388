import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button, 
    Box,
    useToast,
    Input 
  } from "@chakra-ui/react";
  import { format } from "date-fns";
  import React, { useEffect, useState } from "react";
  import { SequencesList } from "../../utils/SequencesList";
  import { ApprovedEmailLists } from "../../utils/ApprovedEmailLists";
  import { ApprovalConfirmDialogBox } from "./ApprovalConfirmDialogBox";
  import { SequencesLists } from "../../utils/SequencesLists";
  import { useNavigate } from 'react-router-dom';
  
  
  const ScoutApproveModel = ({
    sequences,
    // emailList,
    isOpen,
    // onClose,
    setIsOpenApproveScoutModel,
    // onYes,
    selectedFlatRows,
    fetchScoutData,
  }) => {
    const [selectedSequence, setSelectedSequence] = useState();
    const [selectedEmail, setSelectedEmail] = useState();
    const [isApproveDisabled, setIsApproveDisabled] = useState(true);
    const [isConfirmDialogBoxOpen, setIsConfirmDialogBoxOpen] = useState();
    const [isApproving, setIsApproving] = useState(false);
    const [filterValue, setFilterValue] = useState('')
    const [searchResults, setSearchResults] = useState(sequences);
  console.log("searchesult",searchResults)
    const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
    const token = localStorage.getItem('token')
    console.log("sequence1",sequences)
  
    const toast = useToast();
    const navigate = useNavigate()
  
  
    const approveCampaign = async () => {
      const payload = {
        // prospect_id: selectedCampaign.prospect,
        // influencer_email: selectedCampaign.influencer_email,
        // campaign_id: selectedCampaign.campaign_id,
        sequence_id:selectedSequence.id,
        // sender_email: selectedEmail,
        scouting_inf_id:selectedFlatRows[0].original.scout_id
      };
    
      setIsApproving(true);
      try {
        const response = await fetch(BASE_URL + `/create-scout-campaigns/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify(payload),
        });
        const message = await response.json();
        if (message.code === 'token_not_valid') {
  
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token")
          navigate('/login')
        };
        setIsApproving(false);
        if (response.ok) {
            fetchScoutData();
          // setIsApproving(false);
          setIsConfirmDialogBoxOpen(false);
          // setSelectedEmail(null);
          setIsApproveDisabled(true)
          // setSelectedSequence(null);
          toast({
            title: "Approved",
            description: "Approval Successful",
            status: "success",
            position: "bottom",
            duration: 3000,
            isClosable: true,
          });
          // window.location.reload();
          // setIsApproving(false);
          // setIsConfirmDialogBoxOpen(false);
          // setSelectedEmail(null);
          // setIsApproveDisabled(true)
          // setSelectedSequence(null);
  
        }
      } catch (error) {
        // setIsLoading(false);
        toast({
          title: "Fetch Error.",
          description: "Failed to Approve",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });
  
        setIsApproving(false);
      }
      onClose();
      // setSelectedEmail(null);
      // fetchCampaignData();
    };
  
  
    const onClose = () => {
      setIsOpenApproveScoutModel(false);
      setSearchResults(sequences)
    }
    
    const updateSelectedSequence = (seq) => {
      setSelectedSequence(seq);
    };
    const updateSelectedEmail = (email) => {
      setSelectedEmail(email);
    };
    const onApprove = () => {
      if ((selectedSequence)) {
        setIsConfirmDialogBoxOpen(true);
      }
  
    };
    useEffect(() => {
      if (selectedSequence && selectedEmail) {
        // if(selectedEmail && selectedSequence ){
        setIsApproveDisabled(false);
      } else {
        setIsApproveDisabled(true);
      }
  
    }, [selectedEmail, selectedSequence])
  
  
  
  
    // ......sequence api ..
    const fetchData = async (searchQuery) => {
      try {
        const response = await fetch(BASE_URL + `/allsequence/?name=${searchQuery}`, {
  
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
        }
  
        const data = await response.json();
        setSearchResults(data['Seqeunce'])
      } catch (error) {
        console.log(error)
      }
    }
    const handleInputChange = (e) => {
      const { value } = e.target
      setFilterValue(value)
      fetchData(value);
    }
  
    return (
      <Modal size={"full"} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select a sequence to approve</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {/* <Email List /> */}
              {/* <ApprovedEmailLists
                emailList={emailList}
                updateSelectedEmail={updateSelectedEmail}
              /> */}
              {/* sequences List */}
              {/* <SequencesList
                sequences={sequences}
                updateSelectedSequence={updateSelectedSequence}
              /> */}
  
              <SequencesLists
                filterValue={filterValue}
                setFilterValue={setFilterValue}
                handleInputChange={handleInputChange}
                sequences={searchResults}
                updateSelectedSequence={updateSelectedSequence}
              />
            </Box>
          </ModalBody>
  
          <ModalFooter gap={2}>
            <Button 
            // isDisabled={isApproveDisabled}
             isLoading={isApproving} 
             colorScheme="red" 
             onClick={onApprove}>
              Approve
            </Button>
            <Button colorScheme="yellow" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
        <ApprovalConfirmDialogBox
          isOpen={isConfirmDialogBoxOpen}
          onClose={() => setIsConfirmDialogBoxOpen(false)}
          onYes={approveCampaign}
        />
      </Modal>
    );
  };
  
  export default ScoutApproveModel;
  