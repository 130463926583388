import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Divider,
  Text,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import { IoMail } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import InboxAllEmails from "../Table/InboxAllEmails";
import ReplyModal from "./emailReplyPopup";
import { useDispatch } from "react-redux";
import { setIsActiveFunc } from "../../store/store";
import { setSelectedEmailFunc } from "../../store/store";
import { setAllMailsFunc } from "../../store/store";

const InboxCardModel = ({
  allEmailsData,
  handleGetEmail,
  setIsOpenInboxModel,
  isLoading,
  talentMailStatus,
  handleSettingModel,
  genralUnReadCount,
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [allMails, setAllMails] = useState(false);
  const [isActive, setIsActive] = useState("Unread");
  const [isNonGeneral, setIsNOnGeneral] = useState(false);
  const [allTalentEmailsData, setAllTalentEmailsData] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [Currenttalentname, setcurrenttalentname] = useState("");
  const dispatch = useDispatch();
  const [currentEmail, setCurrentEmail] = useState("");
  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  console.log("allemailsdata>>", allEmailsData);

  // Get the user's role from Redux or localStorage
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  // Find the path based on the user's role
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const hanldeAllEmails = () => {
    setIsNOnGeneral(false);
    setAllMails(true);
    // handleGetEmail()
    // naviagte(`${userRoute}/InboxAllEmails`,{ state: allEmailsData })
  };

  const handleTalentEmails = async (email, talent) => {
    try {
      const response = await fetch(BASE_URL + "/get-talent-mail/?page=1&page_size=15&filter_type=unread", {
        method: "POST",
        body: JSON.stringify({ email: email }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      console.log("data11>>", data);
      if (data.message == "No threads found for the provided email.") {
        setAllTalentEmailsData([]);
      } else {
        setAllTalentEmailsData(data);
      }
      setAllMails(true);
      setIsNOnGeneral(true);

      setSelectedEmail(email);
      setcurrenttalentname(talent);
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.status === 200) {
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (allMails) {
      // Navigate to InboxAllEmails and pass the talent emails state
      let path = `${userRoute}/${Currenttalentname}/Inbox`;

      if (!isNonGeneral) {
        // Change the path if isNonGeneral is false
        path = `${userRoute}/${Currenttalentname}General Inbox/Inbox`;
      }

      navigate(path, {
        state: {
          allEmailsData: isNonGeneral ? allTalentEmailsData : allEmailsData, // Corrected syntax
          // handleGetEmail: handleGetEmail,
          isLoading: isLoading,
          isActive: isActive,
          selectedEmail: selectedEmail,
          isNonGeneral: isNonGeneral,
          currentEmail: currentEmail,
        },
      });

      // Optionally reset the state here if you want to prevent re-navigating
      setAllMails(false);
    }
  }, [allMails, navigate, Currenttalentname]);

  useEffect(() => {
    // Dispatch the functions to the Redux store
    dispatch(setIsActiveFunc(setIsActive));
    dispatch(setSelectedEmailFunc(setSelectedEmail));
    dispatch(setAllMailsFunc(setAllMails));
  }, [dispatch]);

  useEffect(() => {
    setCurrentEmail("");
  }, []);
  console.log("talentMailStatus", talentMailStatus);
  return (
    <>
      {!allMails && (
        <Box>
          <div className="seting_icons">
            <IoSettings
              className="Icon-set"
              style={{
                position: "fixed",
                right: "0",
                fontSize: "1.5rem",
                marginRight: "20px",
                marginTop: "5px",
                zIndex: 1000,
              }}
              onClick={handleSettingModel}
            />{" "}
          </div>
        </Box>
      )}
      <Box className="page_box" style={{ position: "relative", zIndex: 1 }}>
        {/* {allMails ? (
          <InboxAllEmails
            allEmailsData={
              isNonGeneral ? allTalentEmailsData
               :
                allEmailsData}
            handleGetEmail={handleGetEmail}
            setAllMails={setAllMails}
            isLoading={isLoading}
            isActive={isActive}
            setIsActive={setIsActive}
            selectedEmail={selectedEmail}
            setSelectedEmail={setSelectedEmail}
          />
        )
         : */}
        {/* ( */}
        <Box maxW="1200px" mx="auto" px={4} mt={10} className="card_model_list">
          <SimpleGrid
            columns={[1, 2, 4]}
            spacing={6}
            padding={4}
            className="inbox_lists"
          >
            {" "}
            {/* Set grid columns */}
            {/* new card ...................................................*/}
            <Card maxW="200px" align="center" className="inbox_card">
              {" "}
              {/* Adjusted margin-bottom to separate from other cards */}
              <CardBody className="card_top">
                {genralUnReadCount !== 0 && (
                  <span
                    className="notice"
                    style={{
                      position: "absolute",
                      right: "28px",
                      top: "10px", // Adjust the top position as needed
                      // Adjust the left position as needed
                      backgroundColor: "rgb(248, 9, 9)", // Optional: background color for better visibility
                      color: "white", // Text color
                      padding: "5px", // Padding around text
                      borderRadius: "50%", // Rounded corners for the span
                    }}
                  >
                    {genralUnReadCount}
                  </span>
                )}
                {/* <Text fontSize="lg" fontWeight="bold">Special Card</Text> */}
                {/* <IoMail fontSize={100} /> */}
                <div className="box_img">
                  <IoMail
                    onClick={hanldeAllEmails}
                    style={{
                      backgroundColor: "black",
                      fontSize: "130px",
                      width: "100%",
                      height: "auto",
                      color: "white",
                    }}
                  />
                </div>
              </CardBody>
              <Divider />
              <CardFooter>
                <Text fontWeight="bold">General</Text>
              </CardFooter>
            </Card>
            {/* ....................... */}
            {talentMailStatus.map((obj, index) => (
              <Card
                key={index}
                maxW="200px"
                align="center"
                className="inbox_card"
                onClick={() => {
                  // Update state here
                  // Assuming you have a useState hook for state management
                  setCurrentEmail(obj?.inf_inbox_email); // Example of setting state
                  handleTalentEmails(obj?.inf_inbox_email, obj?.talent_name);
                }}
              >
                <CardBody className="card_top">
                  {obj.unread_count !== 0 && (
                    <span
                      className="notice"
                      style={{
                        position: "absolute",
                        right: "20px",
                        top: "10px", // Adjust the top position as needed
                        // Adjust the left position as needed
                        backgroundColor: "rgb(248, 9, 9)", // Optional: background color for better visibility
                        color: "white", // Text color
                        padding: "5px", // Padding around text
                        borderRadius: "50%", // Rounded corners for the span
                      }}
                    >
                      {obj.unread_count}
                    </span>
                  )}
                  <div className="box_img">
                    <Image
                      src={obj.profile_picture}
                      alt={`Professional Image ${index + 1}`}
                      borderRadius="lg"
                      maxW="150px"
                      width="100%"
                    />
                  </div>
                </CardBody>
                <Divider />
                <CardFooter>
                  <Text fontWeight="bold">{obj.talent_name}</Text>{" "}
                  {/* Styled text */}
                </CardFooter>
              </Card>
            ))}
          </SimpleGrid>
        </Box>
        {/* ) */}
        {/* } */}
      </Box>
    </>
  );
};

export default InboxCardModel;
