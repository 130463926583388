import React, { useEffect, useState, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Flex,
  FormControl,
  FormLabel,
  Divider,
  Tag,
  Input,
} from "@chakra-ui/react";
import GmailAuth from "../../GmailAuth";
import { Switch } from "@chakra-ui/react";
import EditTemplateModel from "./EditTemplateModel";

const InboxEmailsModel = ({ isOpen, onClose, autoRespondStatus }) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const [TemplateData, setTemplateData] = useState([]);
  const [isToggled, setIsToggled] = useState(false);
  const [isOpenTemplate, setIsOpenTemplate] = useState(false);
  const [name, setName] = useState("hi");
  const [body, setBody] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [editedKeyword, setEditedKeyword] = useState("");
  const inputRef = useRef(null);

  const fetchTemplates = async () => {
    try {
      // setIsCompanySearchLoading(true);
      const response = await fetch(`${BASE_URL}/get-autorespond-template/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setTemplateData(data.data);

      if (data.code === "token_not_valid") {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      // setFilteredCompanyData(data?.companies);
      // setIsCompanySearchLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchTemplates();
    setIsToggled(autoRespondStatus);
  }, [autoRespondStatus]);

  const handleToggle = async () => {
    const newToggleState = !isToggled; // Flip the toggle state
    setIsToggled(newToggleState); // Update the state
    try {
      // Hit the POST API with the new toggle state
      const response = await fetch(`${BASE_URL}/auto-respond/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          value: newToggleState, // Send true or false based on toggle
        }),
      });
    } catch (error) {
      console.error("Error hitting API:", error);
    }
  };

  const handleSetTemplat = (id, temp, temp2) => {
    setIsOpenTemplate(true);
    setTemplateId(id);
    setName(temp);
    setBody(temp2);
  };

  const handleDoubleClick = (id, currentKeywords) => {
    setEditingTemplateId(id);
    setEditedKeyword(currentKeywords.join(", "));
  };

  const handleKeywordChange = (e) => {
    setEditedKeyword(e.target.value);
  };

  const handleSaveKeywords = async (id) => {
    const updatedKeywords = editedKeyword
      .split(",")
      .map((keyword) => keyword.trim());

    try {
      const response = await fetch(`${BASE_URL}/get-autorespond-template/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ temp_id: id, keywords: updatedKeywords }),
      });

      if (!response.ok) {
        throw new Error("Failed to update keywords");
      }

      // Update the local state with the new keywords
      setTemplateData((prevTemplates) =>
        prevTemplates.map((template) =>
          template.id === id
            ? { ...template, keywords: updatedKeywords }
            : template
        )
      );
    } catch (error) {
      console.error("Error updating keywords:", error);
    }

    setEditingTemplateId(null);
  };

  const handleCancelEdit = () => {
    setEditingTemplateId(null);
    setEditedKeyword("");
  };

  useEffect(() => {
    if (editingTemplateId !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editingTemplateId]);

  return (
    <>
      {/* <Button onClick={onOpen}>Open Modal</Button> */}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          className="index_setting_popup"
          maxWidth="800px"
          minHeight="400px"
          width="90%"
          height="auto"
        >
          <ModalHeader>Inbox Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl display="flex" alignItems="center">
              <FormLabel className="label_feat" htmlFor="email-alerts" mb="0">
                Auto Respond Feature
              </FormLabel>
              <Switch
                id="email-alerts"
                size="lg"
                position={"absolute"}
                right={0}
                isChecked={isToggled}
                onChange={handleToggle}
              />
            </FormControl>
            <Divider />
            <FormLabel className="label_heading" htmlFor="email-alerts" mb="0">
              Auto Respond Templates
            </FormLabel>
            {TemplateData &&
              TemplateData.map((template, index) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={index}
                  mb={4}
                >
                  <Box
                    className="index_inner"
                    htmlFor="template-keywords"
                    mb="0"
                    fontSize="lg"
                  >
                    {template.name}
                  </Box>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    ml={4}
                    position={"absolute"}
                    right={0}
                    onClick={() => {
                      handleSetTemplat(
                        template.id,
                        template.name,
                        template.body
                      );
                    }}
                  >
                    Edit
                  </Button>
                </FormControl>
              ))}
            <Divider />
            <FormLabel htmlFor="email-alerts" mb="0">
              Auto Responds Keywords
            </FormLabel>
            <p>
              Double click to edit the Keywords that you want to use to trigger
              the corresponding auto response,seprate each keyword with a
              comma.click away from the edit box to save.
            </p>
            {TemplateData &&
              TemplateData.map((template) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  key={`keywords-${template.id}`}
                  mb={4}
                >
                  <Box htmlFor="template-keywords" mb="0" fontSize="lg">
                    {template.name} Keyword
                  </Box>
                  {editingTemplateId === template.id ? (
                    <Input
                      ref={inputRef}
                      width="50%"
                      ml={4}
                      position={"absolute"}
                      right={0}
                      value={editedKeyword}
                      onChange={handleKeywordChange}
                      onBlur={() => handleSaveKeywords(template.id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSaveKeywords(template.id);
                        } else if (e.key === "Escape") {
                          handleCancelEdit();
                        }
                      }}
                    />
                  ) : (
                    <Tag
                      ml={4}
                      position={"absolute"}
                      right={0}
                      cursor="pointer"
                      onDoubleClick={() =>
                        handleDoubleClick(template.id, template.keywords)
                      }
                    >
                      {template.keywords.join(", ")}
                    </Tag>
                  )}
                </FormControl>
              ))}
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button> */}
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isOpenTemplate && (
        <EditTemplateModel
          isOpen={isOpenTemplate}
          onClose={() => setIsOpenTemplate(false)}
          templateId={templateId}
          name={name}
          body={body}
        />
      )}
    </>
  );
};

export default InboxEmailsModel;
