import React from "react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Divider,
  HStack,
  Skeleton,
  Text,
  Button,
  ButtonGroup,
  Box,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
// import { GoChevronLeft } from "react-icons/go";
import { useLocation } from "react-router-dom";
import { useState } from "react";
// import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import Checkbox from "./Checkbox";
import "./InboxEmail.css";
import AllMessegesModel from "../Modal/AllMessegesModel";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setIsActiveFunc } from "../../store/store";
import { useDispatch } from "react-redux";
import { setFunction } from "../../store/store";

import { IoMdTrash } from "react-icons/io";
import ScoutDeleteDialogeBox from "../Modal/ScoutDeleteDialogeBox";
import { useToast } from "@chakra-ui/react";

const InboxAllEmails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { allEmailsData, selectedEmail, isNonGeneral, currentEmail } =
    location.state || {}; // Use optional chaining
  const [receivedData, setReceivedData] = useState(allEmailsData);
  const [isActive, setIsActive] = useState("Unread");
  const { colorMode } = useColorMode();
  const [pageCount, setPageCount] = useState(0);
  const [isOpenScoutDeleteDialogBox, setIsOpenScoutsDeleteDialogBox] =
    useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const toast = useToast();
  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState([]);
  console.log("pageIndex>>>", pageIndex);
  console.log("pagecount>>", pageCount);
  console.log("isNonGeneral>>", isNonGeneral);
  // const handleGetEmail = useSelector((state) => state.myFunction)
  console.log("type of handle get mail", typeof setFunction);
  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  console.log("allemailsdata33223>>", receivedData);

  // Get the user's role from Redux or localStorage
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  // Find the path based on the user's role
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const receivedDatas = location.state;

  //const [isLoading, setIsLoading] = useState(false)
  const [messeges, setMesseges] = useState(null);
  // const setIsActive = useSelector((state) => state.setIsActiveFunc);
  const setSelectedEmail = useSelector((state) => state.setSelectedEmailFunc);
  const setAllMails = useSelector((state) => state.setAllMailsFunc);

  console.log("allemailsdata here are", allEmailsData);

  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const UserEmail = localStorage.getItem("userEmail");

  // const snippets = receivedData?.emails?.map((item) => item.messages[0].subject);

  const extractTime = (dateString) => {
    const timeMatch = dateString.match(/\d{2}:\d{2} [APM]{2}/);
    return timeMatch ? timeMatch[0] : "";
  };

  const hanldeTextSubject = async (messages) => {
    setMesseges(messages);

    if (messages.some((email) => email.status === "unread")) {
      // // Filter out the unread message IDs
      // const unreadMessageIds = messages
      //   .filter(message => message.status === 'unread')
      //   .map(message => message.id,message.thread_id);

      // // Hit the fetch POST API
      // try {
      //   const payload = {
      //     ids: unreadMessageIds
      //     thread_id:thread_id
      //   };

      // Filter out the unread message IDs and thread IDs
      const unreadMessages = messages.filter(
        (message) => message.status === "unread"
      );

      // Map to extract both the message IDs and thread IDs
      const unreadMessageIds = unreadMessages.map((message) => message.id);
      const unreadThreadIds = unreadMessages.map(
        (message) => message.thread_id
      );

      // Hit the fetch POST API
      try {
        const payload = {
          ids: unreadMessageIds, // Array of message IDs
          thread_id: unreadThreadIds, // Array of thread IDs
        };

        const response = await fetch(BASE_URL + "/make-read/", {
          method: "POST",
          body: JSON.stringify(payload), // Use JSON.stringify to send payload
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure token is defined
          },
        });

        const data = await response.json();
        if (data.status === "success") {
          if (!isNonGeneral) {
            handleGetEmail("", "unread");
          } else {
            handleTalentEmails("unread");
          }
        }

        if (response.status === 401) {
          console.error("Unauthorized: User session expired");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("token");
          window.location.href = "/login";
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        if (response.status === 200) {
          // handleGetEmail('', 'unread')
          // Handle success logic here
          // Example: toast success, close models, etc.
        }
      } catch (error) {
        // Handle the error

        console.error("Error:", error);
      }
    }
  };

  function removeContentBetweenBrackets(str) {
    // Replace content inside < > along with the brackets
    return str?.replace(/<[^>]*>/g, "").trim();
  }

  function removeHtmlTags(str) {
    // Remove <style> tags and their content, HTML tags, &nbsp;, and extra spaces
    return str
      ? str
          .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, " ") // Remove <style> tags and their content
          .replace(/<[^>]*>/g, " ") // Remove HTML tags
          .replace(/&nbsp;/g, " ") // Remove &nbsp; entities
          .replace(/\s+/g, " ") // Collapse multiple spaces into one
          .trim() // Remove leading/trailing spaces
      : "";
  }

  function getStringWidth(text) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = window.getComputedStyle(document.body).font; // Use the font style of the body or a specific element if needed
    return context.measureText(text).width;
  }

  function sliceStringToFitScreen(text) {
    const screenWidth = window.innerWidth;
    const maxWidth = screenWidth * 0.18; // 50% of the screen width
    const step = 10; // Adjust this step size if needed
    let slicedText = "";
    let currentIndex = 0;

    while (currentIndex < text.length) {
      const chunk = text.slice(currentIndex, currentIndex + step);

      const newText = slicedText + chunk;
      if (getStringWidth(newText) > maxWidth) {
        return slicedText.trimEnd() + "..."; // Add ellipsis if the text overflows
      }
      slicedText = newText;
      currentIndex += step;
    }

    return slicedText;
  }

  // function processString(str) {
  //   const cleanedString = removeHtmlTags(str);
  //   return sliceStringToFitScreen(cleanedString);
  // }

  function processString(str) {
    const decodedString = decodeHtmlEntities(str);
    const cleanedString = removeHtmlTags(decodedString);
    return sliceStringToFitScreen(cleanedString);
  }

  function decodeHtmlEntities(str) {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  }

  const HandleExit = () => {
    navigate(`${userRoute}/Inbox`, { state: receivedData });
    // setIsActive(isActive)
    // setAllMails(null)
    // setSelectedEmail('')
  };

  const handleTalentEmails = async (filter) => {
    try {
      const response = await fetch(
        BASE_URL +
          `/get-talent-mail/?page=${
            pageIndex + 1
          }&page_size=15&filter_type=${filter}`,
        {
          method: "POST",
          body: JSON.stringify({ email: currentEmail }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log("data>>", data);
      if (data.message == "No threads found for the provided email.") {
        setReceivedData([]);
        setPageCount(data?.paginator?.total_pages);
      } else {
        setReceivedData(data);
        setPageCount(data?.paginator?.total_pages);
      }

      // setAllMails(true);
      // setIsNOnGeneral(true);
      // setAllTalentEmailsData(data);
      // setSelectedEmail(email)
      // setcurrenttalentname(talent)
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      if (response.status === 200) {
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleGetEmail = async (pagetoken = "", filter = "") => {
    setIsLoading(true);
    const email = UserEmail;
    try {
      const response = await fetch(
        `${BASE_URL}/get-general-mail/?page=${
          pageIndex + 1
        }&page_size=15&filter_type=${filter}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();
      console.log("data from all emails ", data);
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        console.log("data received", data);
        setReceivedData(data);
        setIsLoading(false);
        setPageCount(data?.paginator?.total_pages);

        // Additional success handling logic here
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show toast, update state, etc.)
    }
  };

  // useEffect(() => {
  //   // Update receivedData if location.state changes
  //   if (allEmailsData)
  //     setReceivedData(allEmailsData);
  //   console.log("hiiiiiiiirec");

  // }, [allEmailsData]);

  // useEffect(()=>{
  // handleGetEmail("",'unread')
  // },[])

  useEffect(() => {
    if (!isNonGeneral) {
      handleGetEmail("", isActive.toLowerCase());
    } else {
      handleTalentEmails(isActive.toLowerCase());
    }
    // Fetch initial data based on isActive
  }, [pageIndex, isActive, isNonGeneral]);

  const handleTabs = (tab) => {
    setIsActive(tab); // Update active tab
    setPageIndex(0); // Reset pageIndex to 0 when tab changes
    if (tab == "Unread") {
      if (!isNonGeneral) {
        handleGetEmail("", "unread");
      } else {
        handleTalentEmails("unread");
      }
      // dispatch(setIsActiveFunc('Unread'))
      setIsActive("Unread");
    }
    if (tab == "Read") {
      if (!isNonGeneral) {
        handleGetEmail("", "read");
      } else {
        handleTalentEmails("read");
      }
      setIsActive("Read");
      // dispatch(setIsActiveFunc('Read'))
    }
    if (tab == "All") {
      if (!isNonGeneral) {
        handleGetEmail("", "all");
      } else {
        handleTalentEmails("all");
      }

      setIsActive("All");
      // dispatch(setIsActiveFunc('All'))
      // handleGetEmail('', "All")
    }

    if (tab == "Offers") {
      if (!isNonGeneral) {
        handleGetEmail("", "offers");
      } else {
        handleTalentEmails("offers");
      }

      setIsActive("Offers");
      // dispatch(setIsActiveFunc('Others'))
    }
    if (tab == "Replies") {
      if (!isNonGeneral) {
        handleGetEmail("", "replies");
      } else {
        handleTalentEmails("replies");
      }
      setIsActive("Replies");
      // dispatch(setIsActiveFunc('Replies'))
    }
    if (tab == "Leads") {
      if (!isNonGeneral) {
        handleGetEmail("", "leads");
      } else {
        handleTalentEmails("leads");
      }
      setIsActive("Leads");
      // dispatch(setIsActiveFunc('Leads'))
    }
    if (tab == "Unofficial") {
      if (!isNonGeneral) {
        handleGetEmail("", "unofficial");
      } else {
        handleTalentEmails("unofficial");
      }
      setIsActive("Unofficial");
      // dispatch(setIsActiveFunc('Unofficial'))
    }
  };
  console.log("recieveddata>>", receivedData);

  const handleNext = () => {
    if (pageIndex < pageCount - 1) {
      setPageIndex(pageIndex + 1);
      // handleGetEmail()
    }
  };

  const handlePrevious = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };
  // Function to handle checkbox selection
  const handleCheckboxChange = (e, item, isChecked) => {
    e.stopPropagation(); // Prevent parent onClick event from being triggered
    if (isChecked) {
      // Add the selected item if the checkbox is checked
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, item]);
    } else {
      // Remove the selected item if the checkbox is unchecked
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((row) => row !== item)
      );
    }
  };
  const threadIdsss = selectedRows.map((item) => item.thread_id);
  console.log("Unique Thread IDs:", threadIdsss);

  const handleDeleteRows = async (selectedRows) => {
    // Extract all message_id values
    const threadIds = selectedRows.map((item) => item.thread_id);

    const messageIds = selectedRows
      .map((item) => item.messages.map((message) => message.thread_id))
      .flat();

    // messageIds threadIds
    console.log("Message IDs:", messageIds);
    console.log("Unique Thread IDs:", threadIds);

    try {
      setIsLoading(true);
      const dealIds = selectedRows.map((row) => row?.original?.deal_id);

      const response = await fetch(BASE_URL + "/trash-message/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          message_id: messageIds,
          thread_id: threadIds,
        }),
      });

      if (response.status === 401) {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate("/login");
        return;
      }

      if (response.ok) {
        setIsLoading(false);

        setReceivedData((prevData) => {
          const filteredEmails = prevData.emails.filter(
            (emailObj) => !threadIds.includes(emailObj.thread_id)
          );

          // Return the updated state with filtered emails
          return {
            ...prevData,
            emails: filteredEmails,
          };
        });

        handleTalentEmails(isActive);
        toast({
          title: "Deletion Success",
          description: "Deal deleted successfully",
          status: "success",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenScoutsDeleteDialogBox(false);
      } else {
        toast({
          title: "Deletion Failed",
          description: "Failed to delete Scout .",
          status: "error",
          position: "bottom",
          duration: 3000,
          isClosable: true,
        });

        setIsOpenScoutsDeleteDialogBox(false);
      }
    } catch (error) {
      toast({
        title: "Deletion Failed",
        description: "Failed to delete Scout.",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });

      setIsOpenScoutsDeleteDialogBox(false);
      console.error("Error:", error);
    }
  };

  return (
    <div>
      {messeges !== null ? (
        <AllMessegesModel
          allEmailsData={receivedData}
          messages={messeges}
          receivedData={receivedData}
          setMesseges={setMesseges}
          isActive={isActive}
          setIsActive={setIsActive}
          selectedEmail={selectedEmail}
        />
      ) : (
        <>
          <div className="button-divider-container">
            {selectedRows.length > 0 && (
              <Button
                onClick={() => setIsOpenScoutsDeleteDialogBox(true)}
                size={"sm"}
                colorScheme="red"
                variant="solid"
                position="absolute"
                right="0px"
                marginRight="6px"
              >
                <IoMdTrash size={20} color="white" />
              </Button>
            )}
            <Button onClick={HandleExit}>
              <GoChevronLeft size={24} />
            </Button>
            <Divider />
          </div>

          <ButtonGroup
            className="email_filter_btn"
            marginLeft={10}
            marginTop={5}
            colorScheme="blue"
            size="sm"
            variant="outline"
            spacing="6"
          >
            <Button
              size="sm"
              onClick={() => handleTabs("Unread")}
              className={isActive == "Unread" ? "active" : ""}
            >
              Unread
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Read")}
              className={isActive == "Read" ? "active" : ""}
            >
              Read
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("All")}
              className={isActive == "All" ? "active" : ""}
            >
              All
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Offers")}
              className={isActive == "Offers" ? "active" : ""}
            >
              Offers
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Replies")}
              className={isActive == "Replies" ? "active" : ""}
            >
              Replies
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Leads")}
              className={isActive == "Leads" ? "active" : ""}
            >
              Leads
            </Button>
            <Button
              size="sm"
              onClick={() => handleTabs("Unofficial")}
              className={isActive == "Unofficial" ? "active" : ""}
            >
              Unofficial
            </Button>
          </ButtonGroup>

          <div className="email_inbox_outer">
            <Skeleton isLoaded={!isLoading}>
              <List spacing={3} my={8} className="email_list_box">
                {/* Check if there are no emails */}
                {receivedData?.emails?.length === 0 ? (
                  <Text
                    textAlign="center"
                    mt={4}
                    fontSize="lg"
                    color="gray.500"
                  >
                    No messages found
                  </Text>
                ) : (
                  receivedData?.emails?.map((item, index) => {
                    const messages = item.messages || [];
                    const hasMessages = messages.length > 0;

                    const subject = hasMessages
                      ? messages[messages.length - 1]?.subject
                      : "No Subject";
                    const snippet = hasMessages
                      ? messages[messages.length - 1]?.snippet
                      : "No Snippet";
                    const from = hasMessages
                      ? messages[messages.length - 1]?.from_email
                      : "No Sender";

                    const status = messages.some(
                      (message) => message.status === "unread"
                    )
                      ? "unread"
                      : "read";
                    const date = hasMessages
                      ? messages[messages.length - 1]?.created_at
                      : null; // Use the latest message or null
                    const time = date
                      ? new Date(date).toLocaleString()
                      : "No Date"; // Format the date or provide a default

                    return (
                      <React.Fragment key={index}>
                        <Divider />
                        <ListItem
                          display={"flex"}
                          justifyContent={"space-between"}
                          my={4}
                          mx={"20px"}
                        >
                          <HStack className="left_emailside">
                            <Checkbox
                              isChecked={selectedRows.includes(item)}
                              onChange={(e) =>
                                handleCheckboxChange(e, item, e.target.checked)
                              }
                            />
                            <Box
                              className="list_row"
                              onClick={() => hanldeTextSubject(item.messages)}
                            >
                              <Text
                                className={
                                  status === "unread"
                                    ? "list_item_t"
                                    : "list_item_unread subject_desc"
                                }
                              >
                                <div className="subject_name">
                                  <span className="from_span">
                                    {removeContentBetweenBrackets(from)}
                                  </span>
                                  <span className="email_tag">
                                    <strong
                                      className={
                                        status === "read"
                                          ? "subject_strong-un"
                                          : "subject_strong"
                                      }
                                    >
                                      {subject}
                                    </strong>
                                    &nbsp;-&nbsp;{" "}
                                    <span className="snippet_span">
                                      {processString(snippet)}
                                    </span>
                                  </span>
                                </div>
                              </Text>
                            </Box>
                          </HStack>
                          <HStack className="right_emailside">
                            <Text
                              className={
                                status === "unread"
                                  ? "time-display"
                                  : "time-display-unread message_time"
                              }
                            >
                              {time}
                            </Text>
                          </HStack>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })
                )}
              </List>
            </Skeleton>
          </div>
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex>
              Page&nbsp;
              <Text fontWeight={500}>
                {pageIndex + 1} of {pageCount}
              </Text>
            </Flex>
            {/* <div className='paents-iconmove'> */}
            <Flex alignItems={"center"} gap={3}>
              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronLeft
                  className="Left-next-page"
                  size={24}
                  onClick={() => handlePrevious()}
                  disabled={pageIndex === 0}
                />
              </Box>

              <Box
                _hover={{
                  bg: colorMode === "light" ? "#EDF2F7" : "#2C313D",
                  cursor: "pointer",
                  borderRadius: "100vh",
                }}
                p={"7px"}
              >
                <GoChevronRight
                  className="Right-next-page"
                  size={24}
                  onClick={() => handleNext()}
                />
              </Box>
            </Flex>

            {/* </div> */}
          </Flex>
          {/* <div>
            <text>{pageIndex +1} of {pageCount}</text>
          </div> */}
          <ScoutDeleteDialogeBox
            isOpen={isOpenScoutDeleteDialogBox}
            onClose={() => {
              setIsOpenScoutsDeleteDialogBox(false);
            }}
            isLoading={isLoading}
            // selectedFlatRows={selectedFlatRows}
            selectedFlatRows={selectedRows}
            handleDeleteRows={handleDeleteRows}
          />
        </>
      )}
    </div>
  );
};

export default InboxAllEmails;
