import { Divider, Button, Flex, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { GoChevronLeft } from "react-icons/go";
import { FaFilePdf } from "react-icons/fa";
import { MdArchive, MdVerifiedUser } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import "./InboxCardModel.css";
import InboxAllEmails from "../Table/InboxAllEmails";
import ReplyModal from "./emailReplyPopup";
import parse from "html-react-parser";
import DOMPurify from "dompurify";

const AllMessegesModel = ({
  allEmailsData,
  messages,
  receivedData,
  setMesseges,
  isActive,
  setIsActive,
  selectedEmail,
}) => {
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const routesConfig = [
    { path: "/admin", role: "Admin" },
    { path: "/creator", role: "Creator Manager" },
    { path: "/influencer", role: "Influencer" },
  ];

  console.log("message ", messages[0].snippet);

  // Get the user's role from Redux or localStorage
  const role =
    useSelector((state) => state.role) || localStorage.getItem("Role");

  // Find the path based on the user's role
  const userRoute = routesConfig.find((route) => route.role === role)?.path;

  const archiveMessage = async () => {
    try {
      const emailId = selectedEmail
        ? selectedEmail
        : localStorage.getItem("userEmail");
      const messageIds = messages.map((message) => message.id);
      const response = await fetch(BASE_URL + "/archieve-message/", {
        method: "POST",
        body: JSON.stringify({
          inbox_email: emailId,
          message_id: messageIds,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.status === 401) {
        console.error("Unauthorized: User session expired");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      if (response.status === 200) {
        return;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDownload = async (attachment) => {
    const res = await fetch(attachment.data, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.ok) {
      const blob = await res.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachment?.filename;
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast({
        title: "Dowloaded Successfully",
        description: attachment.filename,
        status: "success",
        position: "bottom",
        duration: 2000,
        isClosable: true,
      });
    } else {
      console.error("Download failed:", res.status, res.statusText);
    }
  };

  const HandleExit = () => {
    // navigate(`${userRoute}/InboxAllEmails`,{state:receivedData})
    setMesseges(null);
    setIsActive(isActive);
  };
  const formatMessageSnippet = (snippet) => {
    // Sanitize and replace <br> with actual line breaks
    const sanitizedSnippet = DOMPurify.sanitize(snippet);
    return sanitizedSnippet.replace(/<br\s*\/?>/gi, "<br />");
  };
  return (
    <div>
      {messages === null ? (
        <InboxAllEmails allEmailsData={allEmailsData} />
      ) : (
        <>
          <div className="back_arrow_design">
            <div className="button-divider-container">
              <div className="back_handel">
                <Button onClick={HandleExit}>
                  <GoChevronLeft size={24} />
                </Button>
                <Text className="all_emails_main_text">
                  {" "}
                  {messages[0].subject}{" "}
                </Text>{" "}
              </div>
              <MdArchive onClick={archiveMessage} />
            </div>
          </div>

          <Accordion
            defaultIndex={[messages.length - 1]}
            allowMultiple
            className="acc_main_top"
          >
            {messages.map((message) => {
              return (
                <AccordionItem className="acc-item-main">
                  <h2 style={{ padding: "8px 10px" }}>
                    <AccordionButton pb={7} p={0}>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        style={{ fontWeight: "bold" }}
                      >
                        <div className="accordion_button_bar">
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {message.from_email.split("<")[0].trim()}
                            <span>
                              {message.in_prospect && (
                                <MdVerifiedUser
                                  style={{ fontSize: "24px", color: "#4483b3" }}
                                />
                              )}
                            </span>
                          </span>
                          {message?.attachments?.length > 0 && (
                            <AttachmentIcon />
                          )}
                        </div>
                      </Box>
                      <Box>{message.created_at}</Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={6}>
                    {/* Use dangerouslySetInnerHTML to render HTML */}

                    {/* <Box dangerouslySetInnerHTML={{ __html: message.snippet }} /> */}
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: formatMessageSnippet(message.snippet),
                      }}
                      style={{ whiteSpace: "pre-wrap" }} // This allows the line breaks to be respected
                    />

                    <Divider />
                    <Flex>
                      {message.attachments.map((attachment) => (
                        <Flex
                          direction="column"
                          align="center"
                          justify="center"
                          border="1px solid"
                          borderColor="gray.200"
                          borderRadius="md"
                          p={4}
                          w="120px"
                          h="150px"
                          m={2}
                        >
                          <Box
                            as={FaFilePdf}
                            cursor={"pointer"}
                            size="40px"
                            color="red.500"
                            onClick={() => {
                              handleDownload(attachment);
                            }}
                          />
                          <Text
                            onClick={() => {
                              handleDownload(attachment);
                            }}
                            fontSize="sm"
                            mt={2}
                            textAlign="center"
                            noOfLines={2}
                            cursor={"pointer"}
                            isTruncated
                            maxW="100px"
                          >
                            {attachment?.filename}
                          </Text>
                        </Flex>
                      ))}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>

          {isOpen ? (
            <ReplyModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              message={messages}
            />
          ) : (
            <div className="replyBtn">
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                Reply
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AllMessegesModel;
