import React, { useState } from "react";
import {
  Button,
  Box,
  Input,
  Flex,
  Text,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { FaChevronDown } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CgSmileMouthOpen } from "react-icons/cg";
import {
  MdOutlineInsertLink,
  MdOutlineAttachFile,
  MdFormatColorText,
} from "react-icons/md";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./emailReplyPopup.css";
import EmojiPicker from "emoji-picker-react";

const ReplyModal = ({ isOpen, setIsOpen, message }) => {
  const [emailContent, setEmailContent] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(""); // New state for filename
  const [link, setLink] = useState("");
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  console.log("message ", message);
  const toast = useToast();
  const token = localStorage.getItem("token");

  const latestMessage = message.reduce((latest, current) => {
    return new Date(current.created_at) > new Date(latest.created_at)
      ? current
      : latest;
  });

  const recipientEmailMatch = latestMessage.to.match(/<([^>]+)>/);
    const recipientEmail = recipientEmailMatch ? recipientEmailMatch[1] : latestMessage.to;

  const handleSendEmail = async () => {
    const formData = new FormData();
    formData.append("sender", recipientEmail);
    formData.append("to", latestMessage.from_email);
    formData.append("subject", latestMessage.subject);
    formData.append("message_text", formatEmailContent(emailContent));
    formData.append("thread_id", latestMessage.thread_id);
    formData.append("reply_message_id", latestMessage.reply_message_id);
    formData.append("references", latestMessage.references);
    formData.append("in_reply_to", latestMessage.in_reply_to);

    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await fetch(
        "https://api.creativesmatter.com/send-reply/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      toast({
        title: "Email sent.",
        description: "Your email reply has been sent successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setIsOpen(false); // Close modal on success
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Error sending email.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const formatEmailContent = (content) => {
    const formattedContent = content
      .replace(/<p><br><\/p>/g, "<br/>")
      .replace(/<p>/g, "<p>")
      .replace(/\n/g, "<br/>");

    return formattedContent;
  };

  const handleEditorChange = (content) => {
    setEmailContent(content);
  };

  const handleFileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      setFileName(uploadedFile.name); // Set the filename
      toast({
        title: "File uploaded.",
        description: `${uploadedFile.name} has been added.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleInsertLink = () => {
    if (link) {
      const newContent = `${emailContent} <a href="${link}" target="_blank">${link}</a>`;
      setEmailContent(newContent);
      setLink("");
      setShowLinkInput(false);
      toast({
        title: "Link inserted.",
        description: `Link has been added: ${link}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddEmoji = (emojiData) => {
    const emoji = emojiData.emoji;
    setEmailContent((prev) => prev + emoji);
  };

  const modules = {
    toolbar: {
      container: [
        [{ font: [] }, { size: [] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <div className="modal-body">
      <Box
        maxW="1400px"
        height="100%"
        mx="auto"
        p={5}
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="lg"
        backgroundColor="#ffffff"
        position="relative"
      >
        <Stack spacing={4}>
          <Flex align="center">
            {/* <div className="header-linking-area">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<FaChevronDown />}
                  backgroundColor="transparent"
                  color="black"
                ></MenuButton>
                <MenuList>
                  <MenuItem>Reply</MenuItem>
                  <MenuItem>Forward</MenuItem>
                  <MenuItem>Edit subject</MenuItem>
                  <MenuItem>Pop-out reply</MenuItem>
                </MenuList>
              </Menu>
            </div> */}
            <Text w="30px">To:</Text>
            <Input
              placeholder="Travel Soxiety Collabs (collabs@travelsoxiety.com)"
              variant="flushed"
              value={recipientEmail}
              readOnly
            />
          </Flex>

          <div className="custom-quill-container">
            <ReactQuill
              value={emailContent}
              onChange={handleEditorChange}
              theme="snow"
              modules={modules}
              formats={formats}
              className="quill-editor"
            />
          </div>

          <Box as="div" mt="40px">
            <Flex mt={4}>
              <Box display="flex" alignItems="center">
                <Button
                  colorScheme="blue"
                  size="lg"
                  borderRightRadius="0"
                  onClick={handleSendEmail}
                  px={1}
                  py={6}
                >
                  Send
                </Button>

                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<FaChevronDown />}
                    aria-label="Options"
                    size="lg"
                    borderLeftRadius="0"
                    borderLeft="1px solid #000"
                    colorScheme="blue"
                  />
                  <MenuList>
                    <MenuItem>Send Now</MenuItem>
                    <MenuItem>Schedule</MenuItem>
                    <MenuItem>Save as Draft</MenuItem>
                  </MenuList>
                </Menu>
              </Box>

              <Tooltip label="Formatting Text" aria-label="Formatting Text">
                <IconButton
                  ml={2}
                  icon={<MdFormatColorText fontSize="24px" />}
                  aria-label="Formatting Text"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                />
              </Tooltip>

              <input
                type="file"
                id="file-upload"
                style={{ display: "none" }}
                onChange={handleFileUpload}
              />
              <Tooltip label="Attach File" aria-label="Attach File">
                <label htmlFor="file-upload">
                  <IconButton
                    as="span"
                    ml={2}
                    icon={<MdOutlineAttachFile fontSize="24px" />}
                    aria-label="Attach File"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                  />
                </label>
              </Tooltip>

              {/* Display the uploaded file name */}
              {fileName && (
                <Text ml={3} fontSize="sm" color="gray.500">
                  {fileName}
                </Text>
              )}

              <Tooltip label="Insert link" aria-label="Insert link">
                <IconButton
                  ml={2}
                  icon={<MdOutlineInsertLink fontSize="24px" />}
                  aria-label="Insert link"
                  bg="transparent"
                  _hover={{ bg: "transparent" }}
                  onClick={() => setShowLinkInput(!showLinkInput)}
                />
              </Tooltip>

              {showLinkInput && (
                <Input
                  placeholder="Enter link"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleInsertLink()}
                  ml={2}
                />
              )}

              <Popover
                isOpen={showEmojiPicker}
                onClose={() => setShowEmojiPicker(false)}
                closeOnBlur={true}
              >
                <PopoverTrigger>
                  <IconButton
                    ml={2}
                    icon={<CgSmileMouthOpen fontSize="24px" />}
                    aria-label="Insert Emoji"
                    bg="transparent"
                    _hover={{ bg: "transparent" }}
                    onClick={() => setShowEmojiPicker((prev) => !prev)}
                  />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <Box p={2}>
                    <EmojiPicker onEmojiClick={handleAddEmoji} />
                  </Box>
                </PopoverContent>
              </Popover>

              <RiDeleteBin6Line
                style={{
                  fontSize: "24px",
                  right: "25px",
                  position: "absolute",
                }}
              />
            </Flex>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default ReplyModal;
