import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  SkeletonText,
  Stack,
  Text,
  useToast,
  InputLeftElement,
  Input,
  InputGroup,
  InputRightElement

} from "@chakra-ui/react";
import { Card, CardHeader, CardBody, CardFooter } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { VscTrash } from 'react-icons/vsc';
import { useNavigate } from "react-router-dom";
import { NewSequences } from "./NewSequences";
import { SequenceDetails } from "./SequenceDetails";
import { TemplateEditorModal } from "./TemplateEditorModal";
import { TbMailPlus } from 'react-icons/tb';
import { LiaHandPointRightSolid } from 'react-icons/lia';
import { RxCross1 } from "react-icons/rx";
import { PiLinkThin } from "react-icons/pi";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import './Sequences.css'
import { IoDuplicate } from "react-icons/io5";


export const Sequences = () => {
  const [newSequence, setNewSequence] = useState(false);
  const [sequences, setSequences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSequence, setSelectedSequence] = useState();
  // ...
  const [filterValue, setFilterValue] = useState('')
  const [searchResults, setSearchResults] = useState([]);
  const [routeconfigpath,setRouteConfigPath] = useState('');
  const [copy, setCopy] = useState(false);
//  const [sequenceCopyId,setSequenceCopyId] = useState([]);
//  const[showSequenceDetails,setShowSequenceDetails]=useState([])



  const navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
  const token = localStorage.getItem('token')
  const toast = useToast();
  const routesConfig = [
    {
      path: '/admin',
      role: 'Admin'
    },
    {
      path: '/creator',
      role: 'Creator Manager'
    }
    ,
    {
      path: '/influencer',
      role: 'Influencer'
    }
  ];

  const role = useSelector((state) => state.role) || localStorage.getItem('Role');
  useEffect(() => {
    const routeConfig = routesConfig.find((route) => route.role === role);
    if (routeConfig) {
      setRouteConfigPath(routeConfig.path);
    }
  }, [role]);

  

  const handleCreateSequence = async (payload) => {

    // navigate("/admin/sequences/new", { relative: "path" });
    // const payload = {
    //   name: "New Sequence",
    //   description: "Automated Emails",
    // };

    try {
      const response = await fetch(BASE_URL + `/createsequence/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.code === 'token_not_valid') {

        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      };
      if (response.ok) {

        navigate(`${routeconfigpath}/sequences/new`, { state: data }, { relative: "path" });
      // fetchSequences()
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("Error:", error);
    }
  };


  const handleCreateSequenceCopy = async (seq) => {
    try {
      const response = await fetch(BASE_URL + `/copy-sequence/${seq}/`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        // No payload needed if it's a simple POST request
      });
  
      const data = await response.json();

  
      if (data.code === 'token_not_valid') {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token");
        navigate('/login');
        return; // Exit the function after navigation
      }
  
      if (response.ok) {
        
        // Fetch the updated list of sequences
        fetchSequences();
  
        // Optionally, navigate to a new page if needed
        // navigate(`/admin/sequences/new`, { state: data });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  
  //get all sequences
  const fetchSequences = async () => {
    // navigate("/admin/sequences/new", { relative: "path" });
    setIsLoading(true)
    try {
      const response = await fetch(BASE_URL + `/allsequence/`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const data = await response.json();
   
      if (data.code === 'token_not_valid') {

        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      }
      if (response.ok) {
        setIsLoading(false);
        setSequences(data.Seqeunce);
        setSearchResults(data.Seqeunce);
      }
    } catch (error) {
      setIsLoading(false);
      toast({
        title: "Sequences",
        description: "Failed to fetch",
        status: "error",
        position: "bottom",
        duration: 3000,
        isClosable: true,
      });
      console.log("Error:", error);
    }
  };

  const handleNewSequencesClick = () => {
    setNewSequence(true);
  };

  const handleSequenceDelete = async (sequence_id) => {
    const payload = { sequence_id: sequence_id };
    try {
      const response = await fetch(BASE_URL + `/deletesequence/`, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();

      if (data.code === 'token_not_valid') {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("token")
        navigate('/login')
      }
      if (response.ok) {
     
        fetchSequences();
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    //get al existing sequences
    fetchSequences();
  }, []);

  useEffect(() => {

    if (sequences.length > 0 && !sequences.includes(selectedSequence)) setSelectedSequence(sequences[0]);
    // console.log("selectedSequence");
  }, [sequences]);
  // .......
  const handleInputChange = (e) => {
    const { value } = e.target
    setFilterValue(value)
    fetchData(value);
  }
  // ......sequence api ..
  const fetchData = async (searchQuery) => {
    try {
      const response = await fetch(BASE_URL + `/allsequence/?name=${searchQuery}`, {

        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setSearchResults(data['Seqeunce'])

    } catch (error) {
      console.log(error)
    }
  }
  // ..
  const clearSearch = () => {
    setFilterValue("");
    setSearchResults(sequences); 

  };
  


  return (
    <Box px={5} py={3} maxH="calc(100vh - 85px)" className="sequence-new">
      <Box className="full-width-sequences" bgColor={'#F5F5F5'} display={"flex"} h={"calc(100vh - 85px)"} boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px">
        <Box className="width-40"
        // p={2}
        // px={4}
        // w={"30%"}
        // bgColor={"white"}
        // h={"100%"}
        // overflow={"auto"}
        // sx={{
        //   "&::-webkit-scrollbar": { width: "5px" },
        //   "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
        //   "&::-webkit-scrollbar-thumb": { background: "#888",borderRadius: "10px" },
        //   "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
        //   scrollbarWidth: "10px",
        // }}
        // boxShadow={"0 4px 8px rgba(0, 128, 128, 0.4) inset"}
        >
          <Card
            // maxW="md"
            // height={"145px"}
            direction={{ base: "column", sm: "row" }}
            borderRadius={"0.375rem 0 0 0"}
            // overflow="hidden"
            variant="outline"
          // onClick={handleNewSequencesClick}

          // border={newSequence?"3px solid teal":""}
          // _hover={{
          //   cursor: "pointer",
          //   boxShadow: "2px 4px 8px rgba(0, 128, 128, 0.4)",
          // }}
          >
            <Image
              borderRadius={"0.375rem 0 0 0"}
              objectFit="cover"
              // color={'teal.300'}
              maxW={{ base: "100%", sm: "160px" }}
              src="/new_sequence_icon2.png"
              alt="Sequences png"
            />
            {/* <Icon as={IoMdMailOpen}></Icon> */}

            <Stack>
              <CardBody p={"0.5rem"}>
                <Text as={"b"}>
                  Start from scratch
                </Text>

                <Text fontSize={'xs'} my="1">
                  Start with your own sequence and your own emails and tasks
                </Text>

              </CardBody>
              <CardFooter p={"0.5rem"}>
                <Button
                  leftIcon={<TbMailPlus />}
                  size={"sm"}
                  onClick={ () => handleCreateSequence(
                    {
                      name: "New Sequence",
                      description: "Automated Emails",
                    }
                  )}
                  colorScheme="green"
                  fontWeight={"400"}
         
                >
                  ADD
                </Button>
              </CardFooter>
            </Stack>
          </Card>
          {/* all existing sequences */}
          <Box mt={3}>
            <Box bg={"#0088CC"} color={"white"} height={"35px"} lineHeight={"35px"} pl={3} clipPath={"polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%)"}>
              <Flex alignItems={"center"} gap={5}>
                <Text fontSize={"sm"}>Start with pre-made Sequences</Text>
                <LiaHandPointRightSolid />
              </Flex>

            </Box>
            {/* .... */}

            <InputGroup style={{ marginTop: "20px", marginLeft: "20px" }}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.400" />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search Sequences..."
                width={"300px"}
                value={filterValue}
                borderRadius={"100vh"}
                style={{ backgroundColor: "white" }}
                onChange={handleInputChange}

              />
              {filterValue && (
                <InputRightElement cursor="pointer">
                  <CloseIcon w={3} h={3} color="gray.400" onClick={clearSearch} />
                </InputRightElement>
              )}

            </InputGroup>
        
            {/* .... */}
            {/* <Box> */}
            <Box mt={3} maxH={"calc(91vh - 320px)"} overflow={"auto"} sx={{
              "&::-webkit-scrollbar": { width: "5px" },
              "&::-webkit-scrollbar-track": { background: "#f1f1f1", borderRadius: "10px" },
              "&::-webkit-scrollbar-thumb": { background: "#38A169", borderRadius: "10px" },
            }}>
              {searchResults.length > 0 ? (
                searchResults.map((seq, index) => {
                  return (
                    <SkeletonText key={seq.id} noOfLines={5} isLoaded={!isLoading}>
                      <Card
                        maxW="md"
                        my={2}
                        mt={index === 0 ? 0 : 2}
                        mb={index === sequences.length - 1 ? 0 : 2}
                        mx={3}
                        p={"5px"}
                        // overflow="hidden"
                        // variant="elevated"
                        onClick={() => {
                          setSelectedSequence(seq);
                          setNewSequence(false);
                        }}
                        border={selectedSequence && selectedSequence.id === seq.id ? "2px solid teal" : ""}
                        _hover={{
                          cursor: "pointer",
                          boxShadow: "2px 4px 8px rgba(0, 128, 128, 0.4)",
                        }}
                      >
                        <CardBody>
                          <Flex justifyContent={"space-between"}>
                            <Flex gap={2}>
                              <PiLinkThin size={18} color="#38A169" />
                              <Box>
                                <Heading mb={1} size="xs" textDecoration={"underline"} textTransform="uppercase">
                                  {seq.name}
                                </Heading>

                                <Text fontSize="sm" color={"gray.500"}>
                                  {seq.description}
                                </Text>
                              </Box>
                            </Flex>
                            <Box>
                              {

                                (selectedSequence && selectedSequence.id === seq.id) &&
                                <>
                                <Flex>
                                <Box borderRadius={"90%"} bg={"red.400"} color={"white"} p={0.5} _hover={{ bg: "red.600" }} style={{ marginRight: "8px" }} >

                                  <IoDuplicate onClick={ () =>{
                                  setCopy(true); // Set the `copy` state to true on click
                                  handleCreateSequenceCopy(
                                     
                                //  {
                                //  name: `${seq.name}(Copy)`,
                                //  description:seq.description ,
                                //      },
                                   seq.id = selectedSequence.id
                                    
                                  )}} />

                                </Box>

                                <Box borderRadius={"50%"} bg={"red.400"} color={"white"} p={0.5} _hover={{ bg: "red.600" }}>
                                      
                                  <Box borderRadius={"50%"} border="1px solid white" p={0.5}>
                                    <RxCross1 size={12} onClick={() => handleSequenceDelete(seq.id)} />
                                  </Box>
                                </Box>
                                </Flex>
                                  </>
                              }
                            </Box>
                          </Flex>

                        </CardBody>
                      </Card>
                    </SkeletonText>
                  );
                })
              ) : (
                <Flex alignItems={"center"} justifyContent={"center"}>
                  <Text>No available sequences.</Text>
                </Flex>
              )}
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
        {/* right container */}
        <Box className="width-60"
          w={"100%"}
          pl={3}
        // overflow={"auto"}
        // sx={{
        //   "&::-webkit-scrollbar": { width: "5px" },
        //   "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
        //   "&::-webkit-scrollbar-thumb": { background: "#888",borderRadius: "10px" },
        //   "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
        //   scrollbarWidth: "5px",

        // }}
        >
          {/* <Box borderRadius={'5px'} p={5} my={4} bgColor={'white'} 
          boxShadow={"0 4px 4px rgba(0, 0, 0, 0.2) "}>
            {selectedSequence && (
              <SequenceMeta
                fetchSequences={fetchSequences}
                sequence={selectedSequence}
              />
            )}
          </Box> */}

          <Box borderRadius={"0 0.375rem 0 0"}>
            {selectedSequence &&
              <SequenceDetails
               fetchSequences={fetchSequences}
                selectedSequence={selectedSequence}
                // showSequenceDetails={showSequenceDetails}
                // setShowSequenceDetails={setShowSequenceDetails}
                // sequenceCopyId={sequenceCopyId}
                // setSequenceCopyId={setSequenceCopyId}
                
                />
            }

            {/* {selectedSequence && 
              <TemplateEditorModal
              isDisabled={isDisabled}
              isOpen={isTemplateEditorModalOpen}
              template={selectedJob?.email_template}
              // isCreateNewTemplate = {isCreateNewTemplate}
              fetchTemplates={fetchTemplates}
            />
            } */}

          </Box>
        </Box>
      </Box>
    </Box>
  );
};
