import { Box, Input, Flex, Image, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Tag, TagLabel, TagLeftIcon, Text, Tooltip, Wrap, Select, useToast } from "@chakra-ui/react";
import { FcApproval } from 'react-icons/fc';
import { FaCalendarCheck, FaRegClock } from 'react-icons/fa'
import { format, parse, parseISO, isValid } from "date-fns";
import { BiFileBlank } from 'react-icons/bi';
import { GiRadioactive } from 'react-icons/gi';
import { MdIncompleteCircle, MdPendingActions } from 'react-icons/md';
import { Link } from "@chakra-ui/react";
import { RiRecordCircleLine } from 'react-icons/ri';
import { AiOutlineSchedule } from 'react-icons/ai';
import { Fragment, useEffect, useState } from "react";
import { IoLogoLinkedin, IoLogoYoutube, IoLogoInstagram } from "react-icons/io";
import { IoLogoTiktok } from "react-icons/io5";
import { TbCalendarQuestion } from "react-icons/tb";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Button } from '@chakra-ui/react'; //Added by me......
import { FaYoutube, FaInstagram, FaTiktok, FaFacebook, FaSnapchat, FaGlobe } from 'react-icons/fa';  //Added by 
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FaFileContract } from "react-icons/fa";
import "../Table/links.css";
import { FaUpload } from "react-icons/fa";
import Past_Preview from "./Past_Preview";
import { useRef } from "react";
import axios from "axios";
// import { FaFileContract } from "react-icons/fa";



const getIconForDomain = (domain) => {
    if (domain.includes('youtube.com') || domain.includes('youtu.be')) {
        return <FaYoutube />;
    } else if (domain.includes('instagram.com')) {
        return <FaInstagram />;
    } else if (domain.includes('tiktok.com')) {
        return <FaTiktok />;
    } else if (domain.includes('facebook.com')) {
        return <FaFacebook />;
    } else if (domain.includes('snapchat.com')) {
        return <FaSnapchat />;
    } else {
        return <FaGlobe />;
    }
};
const navigateToInfluencerDeal = (influencerId) => {
    // Add your navigation logic here, for example:
   
};//Added by me......

// const BASE_URL = process.env.REACT_APP_LOCAL_BASE_URL;
const BASE_URL = process.env.REACT_APP_DEPLOYED_BASE_URL;
const token = localStorage.getItem('token')
const role = localStorage.getItem('Role')




function isValidISODate(dateString) {
    if (!dateString) {
        return false; // Handle null or undefined input
    }
    const date = new Date(dateString);
    return !isNaN(date);
}


function parseFormattedDate(dateString) {
    // Parse the date string back to a Date object
    const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
    // Validate the parsed date
    return isValid(parsedDate) ? parsedDate : null;
}

const ratesFormat = (num) => {
    return num?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export const COMPANY_COLUMNS = [
    {
        Header: 'Company Logo',
        accessor: 'company.company_logo',
        Cell: ({ value }) => (
            <Image src={value || "http://creativesmatter.com/wp-content/uploads/2024/01/office-building.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/office-building.png' borderRadius='full' boxSize='40px' objectFit='cover' />
        )
    },
    {
        Header: 'Company Name',
        accessor: 'company.company_name',
    },
    // {
    //     Header: 'Company Website',
    //     accessor: 'company.company_website',
    //     Cell: ({ value }) => {
    //         return (
    //             <Link color='teal.500' href={value} isExternal>{value}</Link>
    //         )
    //     }
    // },
    {
        Header: 'Company Domain',
        accessor: 'company.company_domain',
        Cell: ({ value }) => {
            return (
                <Link color='teal.500' href={`https://${value}`} isExternal>{value}</Link>
            )
        }
    },
    {
        Header: 'Company LinkedIn',
        accessor: 'company.company_linkedin_url',
        Cell: ({ value }) => {
            return (
                <Link display={"flex"} alignItems={"center"} gap={1} color='#0A66C2' href={value} isExternal><IoLogoLinkedin size={18} />LinkedIn</Link>
            )
        }
    },
    {
        Header: 'Company Industry',
        accessor: 'company.company_industry',
    },
    {
        Header: 'Location',
        accessor: 'company.location',
    },
    {
        Header: 'Company Specialties',
        accessor: 'company.company_specialties',
        Cell: ({ value }) => {
            const specialties = value?.split(',').map(spec => spec.trim());

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {specialties?.map((specialty, index) => (
                        specialty.length > 1 &&
                        <Tag key={index} variant="solid" colorScheme="" size={"sm"} borderRadius='full' _hover={{ bg: "#26619e" }}>
                            <TagLabel>{specialty}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );

        }
    },
    {
        Header: 'Email Pattern',
        accessor: 'company.email_format',
        Cell: ({ value }) => {
            const specialties = value?.split(',').map(spec => spec.trim());

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {specialties?.map((specialty, index) => (
                        specialty.length > 1 &&
                        <Tag key={index} variant="solid" colorScheme="black" size={"sm"} borderRadius='full' >
                            <TagLabel color="black">{specialty}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    {
        Header: 'Notes',
        accessor: 'company.company_notes',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    // {
    //     Header: 'Employee count',
    //     accessor: 'company.employee_count',
    // },
    // {
    //     Header: 'Created At',
    //     accessor: 'company.created_ts',
    //     Cell: ({ value }) => {
    //         return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
    //     }
    // },
    {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
            <Button
                size="sm"
                colorScheme="teal"
                onClick={() => navigateToInfluencerDeal(row.original.influencer_id)}
            >
                Go to Deal
            </Button>
        ),
    }, //Added by me......
];

export const PROSPECT_COLUMNS = [
    {
        Header: 'Profile Picture',
        accessor: 'profile_picture',
        Cell: ({ value }) => (
            <Image src={value || "http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png' borderRadius='full' boxSize='40px' objectFit='cover' />
        )
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
    },
    // {
    //     Header: 'Full Name',
    //     accessor: 'full_name',
    // },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Position',
        accessor: 'position',
    },
    {
        Header: 'Company Name',
        accessor: 'company_name',
        Cell: ({ value }) => {
            const [companyDetails, setCompanyDetails] = useState(null);

            const handleGetCompanyDetails = async (value) => {
                try {
                    if (value) {
                        const response = await fetch(BASE_URL + `/company/${value}/`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        const data = await response.json();
                        setCompanyDetails(data?.company_details);
                    }
                }
                catch (error) {
                    console.error('Error fetching company details:', error);
                }
            }

            useEffect(() => {
                handleGetCompanyDetails();
            }, [value]);

            const specialties = companyDetails?.company_specialties?.split(',').map(spec => spec.trim());

            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500' onClick={() => handleGetCompanyDetails(value)}>{value}</Link>
                    </PopoverTrigger>
                    {companyDetails && (
                        <PopoverContent w="300px" color='white' bg='black'>
                            <PopoverArrow bg='black' />
                            <PopoverCloseButton />
                            <PopoverHeader>
                                <Link display={"flex"} alignItems={"center"} gap={1} href={`https://${companyDetails?.company_domain}`} isExternal><Image src={companyDetails?.company_logo || "http://creativesmatter.com/wp-content/uploads/2024/01/office-building.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/office-building.png' borderRadius='full' boxSize='40px' objectFit='cover' /> {companyDetails?.company_name}</Link>
                                {/* <Image src={companyDetails?.company_logo || "https://bit.ly/dan-abramov"} fallbackSrc='https://bit.ly/dan-abramov' borderRadius='full' boxSize='40px' objectFit='cover'/>  */}
                            </PopoverHeader>
                            <PopoverBody
                            bg="black"
                            overflowX={"auto"} sx={{
                                "&::-webkit-scrollbar": { height: "3px" },
                                "&::-webkit-scrollbar-thumb": { background: "#ffffff", borderRadius: "10px" },
                            }}>
                                <Flex justifyContent={"space-between"} gap={5}>
                                    <Flex flexDirection={"column"} gap={2}>
                                        {/* <Box>
                                            <Text as="b">Name</Text>
                                            <Link display='flex' href={`https://${companyDetails?.company_domain}`} isExternal><IoMdGlobe size={18} /> {companyDetails?.company_name}</Link>
                                            <Text color={"gray.300"}>{companyDetails?.company_name}</Text>
                                        </Box> */}
                                        {/* <Box>
                                            <Text as="b">Domain</Text>
                                            <Text color={"gray.300"}>{companyDetails?.company_domain}</Text>
                                           
                                        </Box> */}
                                        <Box>
                                            {/* <Text as="b">Linkedin</Text> */}
                                            {/* <Text color={"gray.300"}>{companyDetails?.company_linkedin_url}</Text> */}
                                            <Link display={"flex"} alignItems={"center"} gap={1} color='dodgerblue' href={companyDetails?.company_linkedin_url} isExternal><IoLogoLinkedin size={18} />LinkedIn</Link>
                                        </Box>
                                        {/* </Flex>
                                    <Flex flexDirection={"column"} gap={2}> */}
                                        <Box>
                                            <Text as="b">Industry</Text>
                                            <Text color={"gray.300"}>{companyDetails?.company_industry}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Location</Text>
                                            <Text color={"gray.300"}>{companyDetails?.location}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Specialties</Text>
                                            <Wrap mt={1} maxH="50px" overflowY="auto" sx={{
                                                "&::-webkit-scrollbar": { width: "2px" },
                                                "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                                                "&::-webkit-scrollbar-thumb": { background: "#888" },
                                                "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                                            }}>
                                                {specialties?.map((specialty, index) => (
                                                    specialty.length > 1 &&
                                                    <Tag key={index} variant="solid" colorScheme="blue" size={"sm"} borderRadius='full' _hover={{ bg: "#26619e" }}>
                                                        <TagLabel>{specialty}</TagLabel>
                                                    </Tag>
                                                ))}
                                            </Wrap>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </PopoverBody>
                        </PopoverContent>
                    )}
                </Popover>
            )
        }
    },
    {
        Header: 'LinkedIn URL',
        accessor: 'linkedin_url',
        Cell: ({ value }) => {
            return (
                <Link display={"flex"} alignItems={"center"} gap={1} color='#0A66C2' href={value?.startsWith('linkedin.com') ? `https://${value}` : value} isExternal><IoLogoLinkedin size={18} />LinkedIn</Link>
            )
        }
    },
    {
        Header: 'Location',
        accessor: 'location',
    },
    {
        Header: 'Last Contacted',
        accessor: 'last_contacted',
        Cell: ({ value }) => {
            if (value) {
                
                const localDate = parseISO(value);
                const formattedDate = format(localDate, 'MM-dd-yyyy hh:mm:ss a');
                return formattedDate;
            }
        }
    },
    {
        Header: 'Replied',
        accessor: 'latest_replied_date',
        Cell: ({ value }) => {
            if (value) {
                const localDate = parseISO(value);
                const formattedDate = format(localDate, 'MM-dd-yyyy hh:mm:ss a');
                return formattedDate;
            }
        }
    },
    {
        Header: 'Email Validity',
        accessor: 'email_validity',
    },
    {
        Header: 'Notes',
        accessor: 'prospects_notes',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    // {
    //     Header: 'Created At',
    //     accessor: 'created_ts',
    //     Cell: ({ value }) => {
    //         return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
    //     }
    // },
];

export const CAMPAIGN_COLUMNS = [
    {
        Header: 'Campaign Status',
        accessor: 'campaign_status',
        Cell: ({ value }) => {
            // const statusColor = {
            //     Draft: "#2196F3" , // "Draft" - #FFC107 (amber)
            //     Approved: "#8BC34A" , // "Approved" - #4CAF50 (green)
            //     color: "#F44336" , // "Delivered" - #2196F3 (blue)
            //     color: "#FF9800" , // "Canceled" - #9E9E9E (grey)
            //     color: "#9E9E9E" , // "Shipped" - #FF9800 (orange)
            //     color: "#2196F3" , // "Pending" - #FFC107 (amber)
            // };

            const Status = {
                Active: {
                    scheme: "red",
                    icon: RiRecordCircleLine,
                },
                Approved: {
                    scheme: "orange",
                    icon: FcApproval,
                },
                Pending: {
                    scheme: "blue",
                    icon: TbCalendarQuestion,
                },
                Scheduled: {
                    scheme: "green",
                    icon: AiOutlineSchedule,
                },
                Queue: {
                    scheme: null,
                    icon: FaRegClock,
                },
                Ended: {
                    scheme: "purple",
                    icon: FaCalendarCheck,
                },
            };

            return (
                <Tag color={Status[value]?.scheme} colorScheme={Status[value]?.scheme}>
                    <TagLeftIcon as={Status[value]?.icon} boxSize="0.8rem" />
                    <TagLabel>{value}</TagLabel>
                </Tag>
            )
        }
    },
    {
        Header: 'Match Score',
        accessor: 'matching_tags_count',
        Cell: ({ value }) => {
            let color = '';
            let fontWeight = 'normal';

            if (value >= 90) {
                color = '#006400';
                fontWeight = 'bold';
            } else if (value >= 80) {
                color = '#55DD33';
                fontWeight = 'bold';
            } else if (value >= 70) {
                color = '#FFD700';
                fontWeight = 'bold';
            } else {
                color = 'red';
                fontWeight = 'bold';
            }

            return (
                <span style={{ color, fontWeight }}>
                    {value}%
                </span>
            );
        }
    },
    {
        Header: 'Company Name',
        accessor: 'company_name',
        Cell: ({ value }) => {
            const [companyDomain, setCompanyDomain] = useState(null);
            const [isLoading, setIsLoading] = useState(false);

            const handleCompanyDomain = async (value) => {
                try {
                    if (value) {
                        const response = await fetch(BASE_URL + `/company_domain/${value}/`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        const data = await response.json();
                        setCompanyDomain(data?.company_domain);
                    }
                }
                catch (error) {
                    console.error('Error fetching company domain:', error);
                }
            }

            useEffect(() => {
                handleCompanyDomain();
            }, [value]);

            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500' onClick={() => handleCompanyDomain(value)}>{value}</Link>
                    </PopoverTrigger>
                    {companyDomain && (
                        <PopoverContent width={"115%"} color='white' bg='teal.500'>
                            <PopoverArrow bg='teal.500' />
                            <PopoverHeader>
                                <Link href={`https://${companyDomain}`} isExternal>{companyDomain}</Link>
                            </PopoverHeader>
                            <PopoverCloseButton />
                        </PopoverContent>
                    )}
                </Popover>
            )
        }
    },
    {
        Header: 'Prospects Full Name',
        accessor: 'prospects_full_name',
        Cell: ({ row }) => {
            const [prospectDetails, setProspectDetails] = useState(null);

            const formatDate = (dateString) => {
                if (!dateString) return null;

                const utcDate = parseISO(dateString);
                return `${(utcDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${utcDate
                    .getUTCDate()
                    .toString()
                    .padStart(2, '0')}/${utcDate.getUTCFullYear()} ${utcDate
                        .getUTCHours()
                        .toString()
                        .padStart(2, '0')}:${utcDate
                            .getUTCMinutes()
                            .toString()
                            .padStart(2, '0')}:${utcDate.getUTCSeconds().toString().padStart(2, '0')}`;
            };
            const handleGetProspectDetails = async (value) => {
                try {
                    if (value) {
                        const response = await fetch(BASE_URL + `/prospects/${value}/`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        const data = await response.json();
                        setProspectDetails(data?.prospect_data);
                    }
                } catch (error) {
                    console.error('Error fetching prospect details:', error);
                }
            };

            useEffect(() => {
                handleGetProspectDetails();
            }, [row?.original?.prospect]);

            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500' onClick={() => handleGetProspectDetails(row?.original?.prospect)}>{row?.original?.prospects_full_name}</Link>
                    </PopoverTrigger>
                    {prospectDetails && (
                        <PopoverContent w="300px" color='white' bg='black'>
                            <PopoverArrow bg='black' />
                            <PopoverCloseButton />
                            <PopoverHeader>
                                <Link color={"gray.300"} display={"flex"} alignItems={"center"} gap={1} href={prospectDetails?.linkedin_url} isExternal><Image src={prospectDetails?.profile_picture || "http://creativesmatter.com/wp-content/uploads/2024/01/user.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/user.png' borderRadius='full' boxSize='40px' objectFit='cover' />{prospectDetails?.full_name}</Link>
                            </PopoverHeader>
                            <PopoverBody overflowX={"auto"}
                            bg="black"
                            sx={{
                                "&::-webkit-scrollbar": { height: "3px" },
                                "&::-webkit-scrollbar-thumb": { background: "#ffffff", borderRadius: "10px" },
                            }}>
                                <Flex justifyContent={"space-between"} gap={5}>
                                    <Flex flexDirection={"column"} gap={2}>
                                        {/* <Box>
                                        <Text as="b">Name</Text>
                                        <Text color={"gray.300"}>{prospectDetails?.full_name}</Text>
                                    </Box> */}
                                        <Box>
                                            <Text as="b">Email</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.email}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Position</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.position}</Text>
                                        </Box>
                                        {/* </Flex>
                                <Flex flexDirection={"column"} gap={2}> */}
                                        {/* <Box>
                                        <Text as="b">Company</Text>
                                        <Text color={"gray.300"}>{prospectDetails?.company_name}</Text>
                                    </Box> */}
                                        {/* <Box>
                                        <Text as="b">LinkedIn</Text>
                                        <Link display={"flex"} alignItems={"center"} gap={1} color='dodgerblue' href={prospectDetails?.linkedin_url} isExternal><IoLogoLinkedin size={18} />LinkedIn</Link>
                                    </Box> */}
                                        <Box>
                                            <Text as="b">Location</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.location}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Email Validity</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.email_validity}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Notes</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.prospects_notes}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Last Contacted</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.last_contacted}</Text>
                                        </Box>
                                        <Box>
                                            <Text as="b">Replied</Text>
                                            <Text color={"gray.300"}>{prospectDetails?.latest_replied_date}</Text>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </PopoverBody>
                        </PopoverContent>
                    )}
                </Popover>
            )
        }
    },
    // {
    //     Header: 'Prospect Email',
    //     accessor: 'prospect_email',
    // },
    {
        Header: 'Talent Name',
        accessor: 'influencer_talent_name',
    },
    {
        Header: 'Account',
        accessor: 'account',
    },
    {
        Header: 'Sequence Name',
        accessor: 'sequence_name',
    },
    {
        Header: 'Total Views',
        accessor: 'total_views',
    },
    {
        Header: 'Emails Sent',
        accessor: 'sent_email_count',
    },
    {
        Header: 'Replied',
        accessor: 'replied',
    },
    {
        Header: 'Replied Date',
        accessor: 'replied_date',
        
        Cell: ({ value }) => {
            if (!value) return '';
            const parsedDate = new Date(value);
            const formattedDate = !isNaN(parsedDate)
                ? format(parsedDate, 'yyyy-MM-dd')
                : '';
            return formattedDate;
        }
        
    },
    {
        Header: 'Company Specialties',
        accessor: 'company_specialties',
        Cell: ({ value }) => {
            const specialties = value?.split(',').map(spec => spec.trim());

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {specialties?.map((specialty, index) => (
                        specialty.length > 1 &&
                        <Tag key={index} variant="solid" colorScheme="blue" size={"sm"} borderRadius='full' _hover={{ bg: "#26619e" }}>
                            <TagLabel>{specialty}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    // {
    //     Header: 'Created At',
    //     accessor: 'created_ts',
    //     Cell: ({ value }) => {
    //         return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
    //     }
    // },
    {
        Header: 'Last Update',
        accessor: 'updated_ts',
        Cell: ({ value }) => {
            return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
        }
    },
];


export const  INFLUENCER_CAMPAIGN_COLUMNS = [
    {
        Header: 'Campaign Status',
        accessor: 'campaign_status',
        Cell: ({ value }) => {
            const Status = {
                Active: {
                    scheme: "red",
                    icon: RiRecordCircleLine,
                },
                Approved: {
                    scheme: "orange",
                    icon: FcApproval,
                },
                Pending: {
                    scheme: "blue",
                    icon: TbCalendarQuestion,
                },
                Scheduled: {
                    scheme: "green",
                    icon: AiOutlineSchedule,
                },
                // Queue: {
                //     scheme: null,
                //     icon: FaRegClock,
                // },
                Ended: {
                    scheme: "purple",
                    icon: FaCalendarCheck,
                },
            };

            return (
                <Tag color={Status[value]?.scheme} colorScheme={Status[value]?.scheme}>
                    <TagLeftIcon as={Status[value]?.icon} boxSize="0.8rem" />
                    <TagLabel>{value}</TagLabel>
                </Tag>
            );
        }
    },
    {
        Header: 'Match Score',
        accessor: 'matching_tags_count',
        Cell: ({ value }) => {
            let color = '';
            let fontWeight = 'normal';

            if (value >= 90) {
                color = '#006400';
                fontWeight = 'bold';
            } else if (value >= 80) {
                color = '#55DD33';
                fontWeight = 'bold';
            } else if (value >= 70) {
                color = '#FFD700';
                fontWeight = 'bold';
            } else {
                color = 'red';
                fontWeight = 'bold';
            }

            return (
                <span style={{ color, fontWeight }}>
                    {value}%
                </span>
            );
        }
    },
    {
        Header: 'Company Name',
        accessor: 'company_name',
        Cell: ({ value }) => {
            const [companyDomain, setCompanyDomain] = useState(null);
            const [isLoading, setIsLoading] = useState(false);

            const handleCompanyDomain = async (value) => {
                try {
                    if (value) {
                        const response = await fetch(BASE_URL + `/company_domain/${value}/`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        const data = await response.json();
                        setCompanyDomain(data?.company_domain);
                    }
                }
                catch (error) {
                    console.error('Error fetching company domain:', error);
                }
            };

            useEffect(() => {
                handleCompanyDomain();
            }, [value]);

            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500' onClick={() => handleCompanyDomain(value)}>{value}</Link>
                    </PopoverTrigger>
                    {companyDomain && (
                        <PopoverContent width={"115%"} color='white' bg='teal.500'>
                            <PopoverArrow bg='teal.500' />
                            <PopoverHeader>
                                <Link href={`https://${companyDomain}`} isExternal>{companyDomain}</Link>
                            </PopoverHeader>
                            <PopoverCloseButton />
                        </PopoverContent>
                    )}
                </Popover>
            );
        }
    },
    {
        Header: 'Talent Name',
        accessor: 'influencer_talent_name',
    },
    {
        Header: 'Total Views',
        accessor: 'total_views',
    },
    {
        Header: 'Emails Sent',
        accessor: 'sent_email_count',
    },
    {
        Header: 'Replied',
        accessor: 'replied',
    },
    {
        Header: 'Replied Date',
        accessor: 'replied_date',
        Cell: ({ value }) => {
            if (!value) return '';
            const parsedDate = new Date(value);
            const formattedDate = !isNaN(parsedDate)
                ? format(parsedDate, 'yyyy-MM-dd')
                : '';
            return formattedDate;
        }
    },
    {
        Header: 'Last Update',
        accessor: 'updated_ts',
        Cell: ({ value }) => {
            return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
        }
    },
];

export const INFLUENCER_COLUMNS = [
    {
        Header: 'Profile Picture',
        accessor: 'profile_picture',
        Cell: ({ value }) => (
            <Image src={value || "http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png' borderRadius='full' boxSize='40px' objectFit='cover' />
        )
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
    },
    {
        Header: 'Talent Name',
        accessor: 'talent_name',
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Inbox',
        accessor: 'inbox',
    },
    {
        Header: 'Phone',
        accessor: 'phone',
    },
    {
        Header: 'Shipping Address',
        accessor: 'address',
    },
    {
        Header: 'Birthday',
        accessor: 'birthday',
        Cell: ({ value }) => {
            return format(new Date(value), 'MM/dd/yy');
        }
    },
    {
        Header: 'YouTube Username',
        accessor: 'youTube_username',
        Cell: ({ row }) => {
            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500'>{row?.original?.youTube_username}</Link>
                    </PopoverTrigger>
                    <PopoverContent w="250px" color='white' bg='red.400'>
                        <PopoverArrow bg='red.400' />
                        <PopoverCloseButton />
                        <PopoverHeader>
                            <Link color='white' display={"flex"} alignItems={"center"} gap={1} href={`https://www.youtube.com/${row.original.youTube_username}`} isExternal><IoLogoYoutube size={18} /> {row.original.youTube_username}</Link>
                        </PopoverHeader>
                        <PopoverBody overflowX={"auto"}  bg="red.400" sx={{
                            "&::-webkit-scrollbar": { height: "3px" },
                            "&::-webkit-scrollbar-thumb": { background: "#ffffff", borderRadius: "10px" },
                        }}>
                            <Flex justifyContent={"space-between"} gap={5}>
                                <Flex flexDirection={"column"} gap={2}>
                                    <Box>
                                        <Text>Partial YT Rate : {ratesFormat(row.original.partial_youTube_rate)}</Text>
                                        <Text>Dedicated YT Rate : {ratesFormat(row.original.dedicated_youTube_rate)}</Text>
                                        <Text>YT Shorts Rate : {ratesFormat(row.original.youTube_shorts_rate)}</Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>

            )
        }
    },
    {
        Header: 'Instagram Username',
        accessor: 'instagram_username',
        Cell: ({ row }) => {
            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500'>{row?.original?.instagram_username}</Link>
                    </PopoverTrigger>
                    <PopoverContent w="250px" color='white' bg='#405DE6'>
                        <PopoverArrow bg='#405DE6' />
                        <PopoverCloseButton />
                        <PopoverHeader>
                            <Link color='white' display={"flex"} alignItems={"center"} gap={1} href={`https://www.instagram.com/${row.original.instagram_username}`} isExternal><IoLogoInstagram size={18} /> {row.original.instagram_username}</Link>
                        </PopoverHeader>
                        <PopoverBody overflowX={"auto"}     background='#405DE6' sx={{
                            "&::-webkit-scrollbar": { height: "3px" },
                            "&::-webkit-scrollbar-thumb": { background: "#ffffff", borderRadius: "10px" },
                        }}>
                            <Flex justifyContent={"space-between"} gap={5}>
                                <Flex flexDirection={"column"} gap={2}>
                                    <Box>
                                        <Text>IG Reel Rate : {ratesFormat(row.original.IG_reel_rate)}</Text>
                                        <Text>IG Story Rate : {ratesFormat(row.original.IG_Story_rate)}</Text>
                                        <Text>IG Feed Rate : {ratesFormat(row.original.IG_Feed_rate)}</Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                // <Tooltip borderRadius={5} px={3} py={2} label={
                //     <Flex gap={2}> 
                //         <Box>
                //             <Text>IG Reel Rate :</Text>
                //             <Text>IG Story Rate :</Text>
                //             <Text>IG Feed Rate :</Text>
                //         </Box>
                //         <Box>
                //             <Text>{ratesFormat(row.original.IG_reel_rate)}</Text>
                //             <Text>{ratesFormat(row.original.IG_Story_rate)}</Text>
                //             <Text>{ratesFormat(row.original.IG_Feed_rate)}</Text>
                //         </Box>
                //         <Box>
                //             </Box>                       
                //     </Flex>
                // } hasArrow arrowSize={10} placement='top' bg='pink.400'>
                //     <Link color='teal.500' href={`https://www.instagram.com/${row.original.instagram_username}`} isExternal>{row.original.instagram_username}</Link>
                // </Tooltip>
            )
        }
    },
    {
        Header: 'TikTok Username',
        accessor: 'tikTok_username',
        Cell: ({ row }) => {
            return (
                <Popover placement='right'>
                    <PopoverTrigger>
                        <Link color='teal.500'>{row?.original?.tikTok_username}</Link>
                    </PopoverTrigger>
                    <PopoverContent w="250px" color='white' bg='black'>
                        <PopoverArrow bg='black' />
                        <PopoverCloseButton />
                        <PopoverHeader>
                            <Link color='white' display={"flex"} alignItems={"center"} gap={1} href={`https://www.tiktok.com/${row.original.tikTok_username}`} isExternal><IoLogoTiktok size={18} /> {row.original.tikTok_username}</Link>
                        </PopoverHeader>
                        <PopoverBody bg='black' overflowX={"auto"} sx={{
                            "&::-webkit-scrollbar": { height: "3px" },
                            "&::-webkit-scrollbar-thumb": { background: "#ffffff", borderRadius: "10px" },
                        }}>
                            <Flex justifyContent={"space-between"} gap={5}>
                                <Flex flexDirection={"column"} gap={2}>
                                    <Box>
                                        <Text>TikTok Rate : {ratesFormat(row.original.tikTok_rate)}</Text>
                                    </Box>
                                </Flex>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Popover>
                // <Tooltip borderRadius={5} px={3} py={2} label={
                //     <Flex gap={2}> 
                //         <Box>
                //             <Text>TikTok Rate :</Text>
                //         </Box>
                //         <Box>
                //             <Text>{ratesFormat(row.original.tikTok_rate)}</Text>
                //         </Box>  
                //     </Flex>
                // } hasArrow arrowSize={10} placement='top' bg='purple.400'>
                //     <Link color='teal.500' href={`https://www.tiktok.com/${row.original.tikTok_username}`} isExternal>{row.original.tikTok_username}</Link>
                // </Tooltip>
            )
        }
    },
    // {
    //     Header: 'Partial YouTube Rate',
    //     accessor: 'partial_youTube_rate',
    // },
    // {
    //     Header: 'Dedicated YouTube Rate',
    //     accessor: 'dedicated_youTube_rate',
    // },
    // {
    //     Header: 'YouTube Shorts Rate',
    //     accessor: 'youTube_shorts_rate',
    // },
    // {
    //     Header: 'IG Reel Rate',
    //     accessor: 'IG_reel_rate',
    // },
    // {
    //     Header: 'IG Story Rate',
    //     accessor: 'IG_Story_rate',
    // },
    // {
    //     Header: 'IG Feed Rate',
    //     accessor: 'IG_Feed_rate',
    // },
    // {
    //     Header: 'TikTok Rate',
    //     accessor: 'tikTok_rate',
    // },
    {
        Header: 'Usage Rate',
        accessor: 'usage_rate',
        Cell: ({ value }) => {
            if (value) {
                return (
                    <Text>{ratesFormat(value)}</Text>
                )
            }
        }
    },
    {
        Header: 'Meta Tags',
        accessor: 'meta_tags',
        Cell: ({ value }) => {
            const tags = typeof value === 'string' ? value.split(',') : value;
            if(Array.isArray(tags)){
               
            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {tags.map((tag, index) => (
                        <Tag key={index} variant="solid" colorScheme="purple" size={"sm"} borderRadius='full' _hover={{ bg: "#603fad" }}>
                            <TagLabel>{tag}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );

            }

        }
    },
    {
        Header: 'Partners Domain Names',
        accessor: 'partners_domain_name',
        Cell: ({ value }) => {
            const filteredDomains = value.filter(domain => domain !== null && domain.trim() !== '\u2060');

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {filteredDomains.map((domain, index) => {

                        return (
                            <Tag key={index} variant="solid" colorScheme={domain ? "pink" : ""} size={"sm"} borderRadius='full' _hover={{ bg: domain ? "#aa3270" : "" }}>
                                <TagLabel>{domain}</TagLabel>
                            </Tag>
                        )
                    })}
                </Wrap>
            );
        }
    },
    // {
    //     Header: 'Wishlist Domains',
    //     accessor: 'whitelisted_company_ids',
    //     Cell: ({ row }) => {
    //         const [companyDomains, setCompanyDomains] = useState([]);

    //         const fetchCompanyDomains = async () => {
    //             try {
    //                 const response = await fetch(BASE_URL + `/whitelist-show/${row?.original?.influencer_id}/`);
    //                 const data = await response.json();
    //                 setCompanyDomains(data?.whitelisted_companies);
    //             } catch (error) {
    //                 console.error('Error fetching company domains:', error);
    //             }
    //         };

    //         useEffect(() => {
    //             fetchCompanyDomains();
    //         }, [row?.original?.influencer_id]);

    //         // console.log(row?.original?.influencer_id);
    //         return (
    //             <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
    //                 "&::-webkit-scrollbar": { width: "2px" },
    //                 "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
    //                 "&::-webkit-scrollbar-thumb": { background: "#888" },
    //                 "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
    //             }}>
    //                 {companyDomains?.map(domain => (
    //                     <Tag key={domain?.company_id} variant="solid" colorScheme="green" size={"sm"} borderRadius='full' _hover={{ bg: "#2a7751" }}>
    //                         <TagLabel>{domain?.company_domain}</TagLabel>
    //                     </Tag>
    //                 ))}
    //             </Wrap>
    //         );
    //     }
    // },
    {
        Header: 'WishlistS Domains',
        accessor: 'whitelisted_company_domains',
        Cell: ({ row }) => {
            const [companyDomains, setCompanyDomains] = useState([]);

            const fetchCompanyDomains = async () => {
                try {
                    const response = await fetch(BASE_URL + `/whitelist-show/${row?.original?.influencer_id}/`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    setCompanyDomains(data?.whitelisted_companies);
                } catch (error) {
                    console.error('Error fetching company domains:', error);
                }
            };

            useEffect(() => {
                fetchCompanyDomains();
            }, [row?.original?.influencer_id]);

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {companyDomains?.map(domain => (
                        <Tag key={domain?.company_id} variant="solid" colorScheme="green" size={"sm"} borderRadius='full' _hover={{ bg: "#2a7751" }}>
                            <TagLabel>{domain?.company_domain}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    {
        Header: 'Blacklist Meta Tags',
        accessor: 'blacklisted_company_ids',
        Cell: ({ row }) => {
            const [blacklistMetaTags, setBlacklistMetaTags] = useState([]);

            const fetchBlacklistedMetaTags = async () => {
                try {
                    const response = await fetch(BASE_URL + `/blacklist-show/${row?.original?.influencer_id}/`, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    const data = await response.json();
                    setBlacklistMetaTags(data?.blacklisted_companies);
                } catch (error) {
                    console.error('Error fetching meta tags:', error);
                }
            };

            useEffect(() => {
                fetchBlacklistedMetaTags();
            }, [row?.original?.influencer_id]);

            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {blacklistMetaTags?.map(tag => (
                        <Tag key={tag?.company_id} variant="solid" colorScheme="red" size={"sm"} borderRadius='full' _hover={{ bg: "#b12b2b" }}>
                            <TagLabel maxW="300px" display={"block"}>{tag?.company_specialties}</TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    {
        Header: 'Media Kit',
        accessor: 'media_kit',
        Cell: ({ value }) => {
            // if(Array.isArray(value)){
            return (
                value ? <Link display={"flex"} alignItems={"center"} gap={1} color='#0A66C2' href={value} isExternal>Media Kit</Link> : <Text>Media Kit</Text>
            )

            // }

        }
    },
    {
        Header: 'Signing Date',
        accessor: 'signing_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : 'Select a date';
            return formattedDate
        }
    },
    {
        Header: 'Manager',
        accessor: 'manager',
    },
    {
        Header: 'Notes',
        accessor: 'influencer_notes',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    // {
    //     Header: 'Created At',
    //     accessor: 'created_ts',
    //     Cell: ({ value }) => {
    //         return format(new Date(value), 'MM/dd/yyyy HH:mm:ss');
    //     }
    // },
];

export const ANALYTICS_COLUMNS = [
    {
        Header: 'Campaign Status',
        accessor: 'campaign_status',
        Cell: ({ value }) => {
            const Status = {
                Active: {
                    scheme: "red",
                    icon: RiRecordCircleLine,
                },
                Approved: {
                    scheme: "orange",
                    icon: FcApproval,
                },
                Ended: {
                    scheme: "purple",
                    icon: MdIncompleteCircle,
                },
                Pending: {
                    scheme: "blue",
                    icon: MdPendingActions,
                },
                Scheduled: {
                    scheme: "green",
                    icon: AiOutlineSchedule,
                },
                Queue: {
                    scheme: null,
                    icon: FaRegClock,
                },
            };

            return (
                <Tag color={Status[value]?.scheme} colorScheme={Status[value]?.scheme}>
                    <TagLeftIcon as={Status[value]?.icon} boxSize="0.8rem" />
                    <TagLabel>{value}</TagLabel>
                </Tag>
            )
        }
    },
    {
        Header: 'Campaign Name',
        accessor: 'dataaaign_name',
        Cell: ({ value }) => (
            <Box>
                {value.split('').map((item, index) => (
                    <Fragment key={index}>
                        {item === 'x' ? (
                            <Text as={"b"} fontSize='xl' color={"red.500"}>{item}</Text>
                        ) : (
                            '' + item + ''
                        )}
                    </Fragment>
                ))}
            </Box>
        ),
    },

    {
        Header: 'Company Name',
        accessor: 'company_name',
    },
    {
        Header: 'Talent Name',
        accessor: 'influencer_talent_name',
    },
    {
        Header: 'Prospect Name',
        accessor: 'prospects_full_name',
    },
    {
        Header: 'Account',
        accessor: 'account',
    },
    {
        Header: 'Sequence Name',
        accessor: 'sequence_name',
    },
    {
        Header: 'Emails Sent',
        accessor: 'sent_email_count',
    },
    {
        Header: 'Total Views',
        accessor: 'total_views',
    },
    // {
    //     Header: 'Total Clicks',
    //     accessor: 'total_clicks',
    // },
    {
        Header: 'Replied',
        accessor: 'replied',
    },
    {
        Header: 'Replied Date',
        accessor: 'replied_date',
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
];

export const USERS_COLUMNS = [
    {
        Header: 'Profile Picture',
        accessor: 'profile_picture_url',
        Cell: ({ value }) => (
            <Image src={value || "http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png' borderRadius='full' boxSize='40px' objectFit='cover' />
        )
    },
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'First Name',
        accessor: 'first_name',
    },
    {
        Header: 'Last Name',
        accessor: 'last_name',
    },
    {
        Header: 'Account Type',
        accessor: 'account_type',
    },
    {
        Header : 'Email_Signature' ,
        accessor : 'email_signature' ,
        Cell: ({ value }) => {
            return (
              <div  className="column-email"
                style={{
                  maxHeight: '70px',
                  maxWidth:'300px', // adjust the height as needed
                  overflowY: 'auto', // allow vertical scrolling
                  textOverflow: 'clip', // show all text when scrolled
                  whiteSpace: 'normal', // allow text to wrap to new lines
                }}
              >
                {value}
              </div>
            );
          }
          
    }

];

export const DEALS_COLUMNS = [
    
    {
        Header: 'Profile Picture',
        accessor: 'Deals_data.profile_picture',
        Cell: ({ value, row }) => {
            const navigate = useNavigate();
            const id= row?.original?.influencer_id
            const routesConfig = [
              { path: '/admin', role: 'Admin' },
              { path: '/creator', role: 'Creator Manager' },
              { path: '/influencer', role: 'Influencer' },
            ];
        
            // Get the user's role from Redux or localStorage
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
        
            // Find the path based on the user's role
            const userRoute = routesConfig.find((route) => route.role === role)?.path;
        
            // Replace spaces with hyphens in the talent name
            const talent_name = row.original.talent_name.replace(/ /g, '-');
        
            // Function to handle view deals
            const handleViewDeals = async () => {
              navigate(`${userRoute}/${talent_name}/cm/${id}`, {
                state: { id: row.original.id, tal_name: row.original.talent_name },
              });
            };
        
            return (
              <Image
                onClick={handleViewDeals}
                src={row.original.profile_picture || 'http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png'}
                fallbackSrc="http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png"
                borderRadius="full"
                boxSize="40px"
                objectFit="cover"
                style={{ cursor: 'pointer' }}
              />
            );
          },
    
    },
    {
        Header: 'Name',
        accessor:'talent_name',
        Cell: ({value, row}) => {
            const id= row?.original?.influencer_id
            const  navigate =useNavigate()
            const routesConfig = [
                {
                  path: '/admin',
                  role: 'Admin'
                },
                {
                  path: '/creator',
                  role: 'Creator Manager'
                }
                ,
                {
                  path: '/influencer',
                  role: 'Influencer'
                }
              ];
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
            const userRoute = routesConfig.find(route => route.role === role)
            ?.path;
          

            
            const talent_name=row.original.talent_name.replace(/ /g, '-')
           
          
                    const handleViewDeals = async () => {
                
                        navigate(`${userRoute}/${(talent_name)}/cm/${id}`,{state : {id:id, tal_name :row.original.talent_name,}})
                        
                     
            }
                        
            return (
                <Link color="steelblue" onClick={handleViewDeals}>{value}</Link>
            );
        }
    },
    {
        Header: 'New Lead',
        accessor: 'deals_summary.New Lead',
    },
    {
        Header: 'Rates Sent',
        accessor: 'deals_summary.Rates Sent',
    },
    {
        Header: 'Offer with Talent',
        accessor: 'deals_summary.Offer with Talent',
    },
    {
        Header: 'Verbal Approval',
        accessor: 'deals_summary.Verbal Approval',
    },
    {
        Header: 'Contract Pending',
        accessor: 'deals_summary.Contract Pending',
    },
    {
        Header: 'Production',
        accessor: 'deals_summary.Production',
    },
    {
        Header: 'Live',
        accessor: 'deals_summary.Live',
    },
    {
        Header: 'Invoiced',
        accessor: 'deals_summary.Invoiced',
    },
    {
        Header: 'Collected',
        accessor: 'deals_summary.Collected',
    },
    {
        Header: 'Closed Won',
        accessor: 'deals_summary.Closed Won',
    },
    {
        Header: 'Closed Lost',
        accessor: 'deals_summary.Closed Lost',
    },
    {
        Header: 'Gifting Only',
        accessor: 'deals_summary.Gifting Only',
    },
    {
        Header: 'Annual Revenue',
        accessor: 'annual_revenue',
        Cell : ({value}) => {
            const newValue=Number(value.toFixed(2))
            const FinalValue = newValue.toLocaleString('en-US', {
                style: 'currency',
                currency : 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            //  console.log(typeof(FinalValue),'typeof>>>>>>>>>>>>>>>>>>>>');
             return FinalValue
             
        }
    },
    {
        Header: 'Current Split',
        accessor: 'current_split',
    },
    {
        Header: 'View Deals',
        accessor: 'influencer_id',
       
        Cell: ({value :id,row}) => {
            
            const  navigate =useNavigate()
            const routesConfig = [
                {
                  path: '/admin',
                  role: 'Admin'
                },
                {
                  path: '/creator',
                  role: 'Creator Manager'
                }
                ,
                {
                  path: '/influencer',
                  role: 'Influencer'
                }
              ];
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
            const userRoute = routesConfig.find(route => route.role === role)
            ?.path;
          

            
            const talent_name=row.original.talent_name.replace(/ /g, '-')
           
          
                // const [infu_data,setInfuData] = useState([])
            const handleViewDeals = async () => {
                
                        navigate(`${userRoute}/${(talent_name)}/cm/${id}`,{state : {id:id, tal_name :row.original.talent_name,}})
                        
                     
            }
            
            return(<Button
                as={Link} 
                // to="/influencer-deals"
                size={"sm"}
                colorScheme="blue"
                onClick={handleViewDeals}
            >
                View Deals
            </Button>)
        }
    },
];

export const INFLUENCERS_DEALS_COLUMNS = [
   
    
    {
        Header: 'Deal Name',
        accessor: 'deal_name',
       
    },
    {
        Header: 'Date Recieved',
        accessor: 'date_received',
        Cell: ({ value }) => {
        
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Stage',
        accessor: 'stage',
        Cell: ({ row,value })=> {
            // console.log("row>stage>",row,value)
        }
        
    },
    {
        Header: 'Deal Type',
        accessor: 'deal_type',
    },
    {
        Header: 'Brand/Agency',
        accessor: 'brand_agency',
    },
    {
        Header: 'Brand Rep',
        accessor: 'brand_rep',       
    },
    {
        Header: 'Creator',
        accessor: 'creator',
        Cell: ({value})=>{
           return value.replace(/-/g, ' '); 
        }
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ value }) => {
            return (
                <Wrap
                    maxH="60px"
                    overflowY="auto"
                    pr="2"
                    w="250px"
                    sx={{
                        "&::-webkit-scrollbar": { width: "2px" },
                        "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                        "&::-webkit-scrollbar-thumb": { background: "#888" },
                        "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    }}
                >
                    <Box
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                        w="full"
                    >
                        {value}
                    </Box>
                </Wrap>
            );
        }
    },
    {
        Header: 'Product Links',
        accessor: 'products',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                    {!row.isEditing ? (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={links[index].trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    Product {index + 1}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    ) : (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={link.trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {link.trim()}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    )}
                </>
            );
        }
    },
    {
        Header: 'Deal Amount',
        accessor: 'deal_amount',
        Cell :({value}) => {
            if (value == null || value === '') {
                return '';
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return (newValue)
         
        }

    },
    {
        Header: 'CM Split',
        accessor: 'cm_split',
        Cell :({value})=> {
            if (value == null || value === '') {
                return '';
            }
            const Values = `${value}%`
            return (Values);
        }

    },
    {
        Header: 'Manager Split',
        accessor: 'manager_split',
        Cell :({value,row})=> {
            if (value == null || value === '') {
                return '';
            }
            const NewValue = `${value}%`
            // console.log(row.original.cm_split,"");
            return (NewValue);
        }
    },
    {
        Header: 'Deliverables',
        accessor: 'deliverables',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    {
        Header: 'Usage',
        accessor: 'usage',
    },
    {
        Header: 'Exclusivity',
        accessor: 'exclusivity',
    },
    {
        Header: 'Rough Draft Date',
        accessor: 'rough_draft_date',
        Cell: ({ value }) => {
         
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Live Date',
        accessor: 'live_date',
        Cell: ({ value }) => {
          
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate 
        }
    },
    {
        Header: 'Live Links',
        accessor: 'live_links',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                 
            {!row.isEditing ? (
                links.map((link, index) => {
                    let trimmedLink = link.trim();
                    if (!trimmedLink.startsWith('http://') && !trimmedLink.startsWith('https://')) {
                        trimmedLink = 'https://' + trimmedLink; // prepend https:// if not already provided
                    }
                    let url;
                    let domain = '';
                    try {
                        url = new URL(trimmedLink);
                        domain = url.hostname;
                       
                    } catch (error) {
                        console.error('Invalid URL:', trimmedLink);
                        return (
                            <span key={index}>
                                {trimmedLink} 
                                {index < links.length - 1 && ', '}
                            </span>
                        );
                    }
                    return (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }} >
                            {getIconForDomain(domain)}
                            <a href={url.href} target="_blank" rel="noopener noreferrer">
                                {domain}
                            </a>
                            {index < links.length - 1 && ''}
                        </span>
                    );
                })
            ) : (
                links.map((link, index) => (
                    <span key={index}>
                        <a href={link.trim()} target="_blank" rel="noopener noreferrer">
                            {link.trim()}
                        </a>
                        {index < links.length - 1 && ', '}
                    </span>
                ))
            )}
                </>
            );
        }
    },
    {
        Header: 'Contract',
        accessor: 'contract',
   
    },
    {
        Header: 'Tracking info',
        accessor: 'tracking_info',
    },
    {
        Header: 'Payment Terms',
        accessor: 'payment_term',
    },
    {
        Header: 'Invoice Contact',
        accessor: 'invoice_contact',
    },
    {
        Header: 'Invoice Date',
        accessor: 'invoice_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Invoice',
        accessor: 'invoices',
        Cell: ({ value }) => {
            if (!value || !Array.isArray(value) || value.length === 0) return '';
    
            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value.map((invoice, index) => (
                        invoice.invoice_number.trim().length > 0 &&
                        <Tag key={index} variant="solid" colorScheme="black" size="sm" borderRadius="full">
                            <TagLabel color="black">
                                <a href={`${BASE_URL}${invoice.pdf}`}
                                 target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color:  'steelblue',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                         color :'#2A4D6D'
                                    }
                                }}
                                  >
                                    {invoice.invoice_number}
                                </a>
                            </TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    {
        Header: 'Payout Date',
        accessor: 'payout_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Creators Payout',
        accessor: 'creator_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }
       
   
    },
    {
        Header: 'Manager Payout',
        accessor: 'manager_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }
       
    },
    {
        Header: 'CM Payout',
        accessor: 'cm_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }

    },
    {
        Header: 'Follow-Up',
        accessor: 'follow_up',
        Cell :() =>{
            
        }

    },
    {
        Header: 'View Deals',
        accessor: 'view_deals', 
        Cell: ({value,row}) => {
            const  navigate =useNavigate()
            const location = useLocation()
             const routesConfig = [
                {
                  path: '/admin',
                  role: 'Admin'
                },
                {
                  path: '/creator',
                  role: 'Creator Manager'
                }
                ,
                {
                  path: '/influencer',
                  role: 'Influencer'
                }
              ];
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
            const userRoute = routesConfig.find(route => route.role === role)
            ?.path;
         


            const deal_id = row.original?.deal_id
            const parts = window.location.pathname.split("/")
            const talent_name = parts[parts.length-3].replace(/ /g, '-')
            const id = parts[parts.length-1]
              localStorage.removeItem('allDealsPage') 

            const handleViewDeals = async () => {              
                        navigate(`${userRoute}/${(talent_name)}/${deal_id}`,
                        {state :
                            {data:row.original,
                             talent_name:talent_name,
                             influencer_id:id,
                            }
                        })                      
 
            }
            
            return(<Button
                as={Link} 
                // to="/influencer-deals"
                size={"sm"}
                colorScheme="blue"
                onClick={handleViewDeals}
            >
                View
            </Button>)
        }
    },
];
export const RELEVANT_INFLUENCERS_DEALS_COLUMNS = [
    {
        Header: 'Deal Name',
        accessor: 'deal_name',
    },
    {
        Header: 'Date Received',
        accessor: 'date_received',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate;
        }
    },
    {
        Header: 'Stage',
        accessor: 'stage',
        Cell: ({ row, value }) => {
            // Optional: Add functionality here if needed
        }
    },
    {
        Header: 'Deal Type',
        accessor: 'deal_type',
    },
    {
        Header: 'Brand/Agency',
        accessor: 'brand_agency',
    },
    {
        Header: 'Deal Amount',
        accessor: 'deal_amount',
        Cell: ({ value }) => {
            if (value == null || value === '') {
                return '';
            }
            const formatValue = parseFloat(value);
            const newValue = formatValue.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return newValue;
        }
    },
    {
        Header: 'Deliverables',
        accessor: 'deliverables',
        Cell: ({ value }) => {
            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    {
        Header: 'Rough Draft Date',
        accessor: 'rough_draft_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate;
        }
    },
    {
        Header: 'Live Date',
        accessor: 'live_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate;
        }
    },
    {
        Header: 'Live Links',
        accessor: 'live_links',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                    {!row.isEditing ? (
                        links.map((link, index) => {
                            let trimmedLink = link.trim();
                            if (!trimmedLink.startsWith('http://') && !trimmedLink.startsWith('https://')) {
                                trimmedLink = 'https://' + trimmedLink; // prepend https:// if not already provided
                            }
                            let url;
                            let domain = '';
                            try {
                                url = new URL(trimmedLink);
                                domain = url.hostname;
                            } catch (error) {
                                console.error('Invalid URL:', trimmedLink);
                                return (
                                    <span key={index}>
                                        {trimmedLink}
                                        {index < links.length - 1 && ', '}
                                    </span>
                                );
                            }
                            return (
                                <span key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                    {getIconForDomain(domain)}
                                    <a href={url.href} target="_blank" rel="noopener noreferrer">
                                        {domain}
                                    </a>
                                    {index < links.length - 1 && ''}
                                </span>
                            );
                        })
                    ) : (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={link.trim()} target="_blank" rel="noopener noreferrer">
                                    {link.trim()}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    )}
                </>
            );
        }
    },
    {
        Header: 'Contract',
        accessor: 'contract',
    },
    {
        Header: 'Tracking info',
        accessor: 'tracking_info',
    },
    {
        Header: 'Invoice Date',
        accessor: 'invoice_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate;
        }
    },
    {
        Header: 'Payout Date',
        accessor: 'payout_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate;
        }
    },
    {
        Header: 'Creator’s Payout',
        accessor: 'creator_payout',
        Cell: ({ value }) => {
            if (value == null || value === '') {
                return null;
            }
            const formatValue = parseFloat(value);
            const newValue = formatValue.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            return newValue;
        }
    },
    {
        Header: 'Usage',
        accessor: 'usage',
    },
    {
        Header: 'Exclusivity',
        accessor: 'exclusivity',
    },
    {
        Header: 'Product Links',
        accessor: 'products',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                    {!row.isEditing ? (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={links[index].trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    Product {index + 1}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    ) : (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={link.trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {link.trim()}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    )}
                </>
            );
        }
    },
    {
        Header: 'View Deals',
        accessor: 'view_deals', 
        Cell: ({value,row}) => {
            const  navigate =useNavigate()
            const location = useLocation()
             const routesConfig = [
                {
                  path: '/admin',
                  role: 'Admin'
                },
                {
                  path: '/creator',
                  role: 'Creator Manager'
                }
                ,
                {
                  path: '/influencer',
                  role: 'Influencer'
                }
              ];
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
            const userRoute = routesConfig.find(route => route.role === role)
            ?.path;
         


            const deal_id = row.original?.deal_id
            const parts = window.location.pathname.split("/")
            const talent_name = row.original?.creator
            const id = row.original?.influencer
            
              localStorage.removeItem('allDealsPage') 
            const handleViewDeals = async () => {              
                        navigate(`${userRoute}/${(talent_name)}/${deal_id}`,
                        {state :
                            {data:row.original,
                             talent_name:talent_name,
                             influencer_id:id,
                            }
                        })                      
 
            }
            
            return(<Button
                as={Link} 
                // to="/influencer-deals"
                size={"sm"}
                colorScheme="blue"
                onClick={handleViewDeals}
            >
                View
            </Button>)
        }
    },
];

export const Past_Preview_COLUMNS = [
{
    Header: "File NAME",
    accessor: 'file_name',
//     Cell:(value)=>{
//    return <a>{value}</a>
//     }
},
{
    Header: "Date Time",
    accessor: "created_at",
    Cell: ({ value }) => format(new Date(value), "MM/dd/yyyy HH:mm a") // Format the date directly in the cell
}

];
export const ALL_DEALS_COLUMNS = [
    
    {
        Header: 'Deal Name',
        accessor: 'deal_name',
       
    },
    {
        Header: 'Date Recieved',
        accessor: 'date_received',
        Cell: ({ value }) => {
        
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Stage',
        accessor: 'stage',
        Cell: ({ row,value })=> {
            // console.log("row>stage>",row,value)
        }
        
    },
    {
        Header: 'Deal Type',
        accessor: 'deal_type',
    },
    {
        Header: 'Brand/Agency',
        accessor: 'brand_agency',
    },
    {
        Header: 'Brand Rep',
        accessor: 'brand_rep',       
    },
    {
        Header: 'Creator',
        accessor: 'creator',
   
    },
    {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ value }) => {
            return (
                <Wrap
                    maxH="60px"
                    overflowY="auto"
                    pr="2"
                    w="250px"
                    sx={{
                        "&::-webkit-scrollbar": { width: "2px" },
                        "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                        "&::-webkit-scrollbar-thumb": { background: "#888" },
                        "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    }}
                >
                    <Box
                        whiteSpace="pre-wrap"
                        wordBreak="break-word"
                        w="full"
                    >
                        {value}
                    </Box>
                </Wrap>
            );
        }
    },
    {
        Header: 'Product Links',
        accessor: 'products',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                    {!row.isEditing ? (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={links[index].trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    Product {index + 1}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    ) : (
                        links.map((link, index) => (
                            <span key={index}>
                                <a href={link.trim()} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                                    {link.trim()}
                                </a>
                                {index < links.length - 1 && ', '}
                            </span>
                        ))
                    )}
                </>
            );
        }
    },
    {
        Header: 'Deal Amount',
        accessor: 'deal_amount',
        Cell :({value}) => {
            if (value == null || value === '') {
                return '';
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return (newValue)
         
        }

    },
    {
        Header: 'CM Split',
        accessor: 'cm_split',
        Cell :({value})=> {
            if (value == null || value === '') {
                return '';
            }
            const Values = `${value}%`
            return (Values);
        }

    },
    {
        Header: 'Manager Split',
        accessor: 'manager_split',
        Cell :({value,row})=> {
            if (value == null || value === '') {
                return '';
            }
            const NewValue = `${value}%`
            // console.log(row.original.cm_split,"");
            return (NewValue);
        }
    },
    {
        Header: 'Deliverables',
        accessor: 'deliverables',
        Cell: ({ value }) => {

            return (
                <Wrap maxH="60px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    {value}
                </Wrap>
            );
        }
    },
    {
        Header: 'Usage',
        accessor: 'usage',
    },
    {
        Header: 'Exclusivity',
        accessor: 'exclusivity',
    },
    {
        Header: 'Rough Draft Date',
        accessor: 'rough_draft_date',
        Cell: ({ value }) => {
         
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Live Date',
        accessor: 'live_date',
        Cell: ({ value }) => {
          
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate 
        }
    },
    {
        Header: 'Live Links',
        accessor: 'live_links',
        Cell: ({ value, row }) => {
            const links = value ? value.split(',') : [];
            return (
                <>
                 
            {!row.isEditing ? (
                links.map((link, index) => {
                    let trimmedLink = link.trim();
                    if (!trimmedLink.startsWith('http://') && !trimmedLink.startsWith('https://')) {
                        trimmedLink = 'https://' + trimmedLink; // prepend https:// if not already provided
                    }
                    let url;
                    let domain = '';
                    try {
                        url = new URL(trimmedLink);
                        domain = url.hostname;
                       
                    } catch (error) {
                        console.error('Invalid URL:', trimmedLink);
                        return (
                            <span key={index}>
                                {trimmedLink} 
                                {index < links.length - 1 && ', '}
                            </span>
                        );
                    }
                    return (
                        <span key={index} style={{ display: 'flex', alignItems: 'center', gap: '5px' }} >
                            {getIconForDomain(domain)}
                            <a href={url.href} target="_blank" rel="noopener noreferrer">
                                {domain}
                            </a>
                            {index < links.length - 1 && ''}
                        </span>
                    );
                })
            ) : (
                links.map((link, index) => (
                    <span key={index}>
                        <a href={link.trim()} target="_blank" rel="noopener noreferrer">
                            {link.trim()}
                        </a>
                        {index < links.length - 1 && ', '}
                    </span>
                ))
            )}
                </>
            );
        }
    },
    {
        Header: 'Contract',
        accessor: 'contract',
   
    },
    {
        Header: 'Tracking info',
        accessor: 'tracking_info',
    },
    {
        Header: 'Payment Terms',
        accessor: 'payment_term',
    },
    {
        Header: 'Invoice Contact',
        accessor: 'invoice_contact',
    },
    {
        Header: 'Invoice Date',
        accessor: 'invoice_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Invoice',
        accessor: 'invoices',
        Cell: ({ value }) => {
            if (!value || !Array.isArray(value) || value.length === 0) return '';

    
            return (
                <Wrap maxH="50px" overflowY="auto" pr="2" sx={{
                    "&::-webkit-scrollbar": { width: "2px" },
                    "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                    "&::-webkit-scrollbar-thumb": { background: "#888" },
                    "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
                    overflowY: 'scroll'
                }}>
                    
           
                    {value.map((invoice, index) => (
                         
                        invoice.invoice_number.trim().length > 0 &&
                        <Tag key={index} variant="solid" colorScheme="black" size="sm" borderRadius="full">
                            <TagLabel color="black">
                                <a href={`${BASE_URL}${invoice.pdf}`}
                                 target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    color:  'steelblue',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                         color :'#2A4D6D'
                                    }
                                }}
                                  >
                                   
                                    {invoice.invoice_number}
                                </a>
                            </TagLabel>
                        </Tag>
                    ))}
                </Wrap>
            );
        }
    },
    {
        Header: 'Payout Date',
        accessor: 'payout_date',
        Cell: ({ value }) => {
            const parsedDate = parseFormattedDate(value);
            const formattedDate = (value && isValidISODate(value) && parsedDate) ? format(parsedDate, 'MM/dd/yyyy') : '';
            return formattedDate
        }
    },
    {
        Header: 'Creators Payout',
        accessor: 'creator_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }
       
   
    },
    {
        Header: 'Manager Payout',
        accessor: 'manager_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }
       
    },
    {
        Header: 'CM Payout',
        accessor: 'cm_payout',
        Cell :({value}) => {
            if (value == null || value === '') {
                return null;
            }
            const Formatvalue=parseFloat(value)
            const newValue=Formatvalue.toLocaleString('en-US',{
            style:'currency',
            currency:'USD',
            minimumFractionDigits:2,
            maximumFractionDigits:2
           })
         return newValue
         
        }

    },
    {
        Header: 'Follow-Up',
        accessor: 'follow_up',
        Cell :() =>{
            
        }

    },
    {
        Header: 'View Deals',
        accessor: 'view_deals', 
        Cell: ({value,row}) => {
            const  navigate =useNavigate()
            const location = useLocation()
             const deal_id = row.original?.deal_id
             const talent_name =row.original?.creator

             const routesConfig = [
                {
                  path: '/admin',
                  role: 'Admin'
                },
                {
                  path: '/creator',
                  role: 'Creator Manager'
                }
                ,
                {
                  path: '/influencer',
                  role: 'Influencer'
                }
              ];
            const role = useSelector((state) => state.role) || localStorage.getItem('Role');
            const userRoute = routesConfig.find(route => route.role === role)
            ?.path;
          

         
            //    const parts = window.location.pathname.split("/")
            //    const talent_name = location?.state?.tal_name?.replace(/ /g, '-')
            //    const talent_name = parts[parts.length-3].replace(/ /g, '-')
             const id =row.original?.influencer
      
               
            const handleViewDeals = async () => {              
                        navigate(`${userRoute}/${(talent_name)}/${deal_id}`,{state : {data:row.original, talent_name:talent_name,influencer_id:id}})                      
 
            }
            
            return(<Button
                as={Link} 
                // to="/influencer-deals"
                size={"sm"}
                colorScheme="blue"
                onClick={handleViewDeals}
            >
                View
            </Button>)
        }
    },
];

export const PROFILE_COLUMNS = [
    { Header: 'Profile Picture', accessor: 'profilePicture' },
    { Header: 'First Name', accessor: 'firstName' },
    { Header: 'Last Name', accessor: 'lastName' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Birthday', accessor: 'birthday' },
    { Header: 'YouTube Username', accessor: 'youtubeUsername' },
    { Header: 'Instagram Username', accessor: 'instagramUsername' },
    { Header: 'TikTok Username', accessor: 'tiktokUsername' },
    { Header: 'Partial YouTube Rate', accessor: 'partialYoutubeRate' },
    { Header: 'Dedicated YouTube Rate', accessor: 'dedicatedYoutubeRate' },
    { Header: 'YouTube Shorts Rate', accessor: 'youtubeShortsRate' },
    { Header: 'IG Reel Rate', accessor: 'igReelRate' },
    { Header: 'IG Story Rate', accessor: 'igStoryRate' },
    { Header: 'IG Feed Rate', accessor: 'igFeedRate' },
    { Header: 'TikTok Rate', accessor: 'tiktokRate' },
    { Header: 'Usage Rate', accessor: 'usageRate' },

];


export const SCOUTED_COLUMN = [
   
    {
        Header: 'Profile Picture',
        accessor: 'profile_picture',
        Cell: ({ value }) => (
            <Image src={value || "http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png"} fallbackSrc='http://creativesmatter.com/wp-content/uploads/2024/01/user-2.png' borderRadius='full' boxSize='40px' objectFit='cover' />
        )
    },
    {
        Header: 'Talent Name',
        accessor: 'talent_name',
    },
    {
        Header: 'First Name',
        accessor: 'name',
    },
   
    {
        Header: 'Email',
        accessor: 'email',
    },
    {
        Header: 'Vertical',
        accessor: 'Vertical',
    },
    {
        Header: 'YouTube Username',
        accessor: 'youTube_username',
        Cell: ({ row }) => {
            return (
                        <Link color='teal.500' href={`https://www.youtube.com/${row.original.youTube_username}`} isExternal>{row?.original?.youTube_username}</Link>
                
            )
        }
    },
    {
        Header: 'Instagram Username',
        accessor: 'instagram_username',
        Cell: ({ row }) => {
            return (
               
                        <Link color='teal.500' href={`https://www.instagram.com/${row.original.instagram_username}`} isExternal>{row?.original?.instagram_username}</Link>
            )
        }
    },
    {
        Header: 'TikTok Username',
        accessor: 'tikTok_username',
        Cell: ({ row }) => {
            return (
               
                        <Link color='teal.500' href={`https://www.tiktok.com/${row.original.tikTok_username}`} isExternal>{row?.original?.tikTok_username}</Link>
            )
        }
    },
    {
        Header: 'Assigned To',
        accessor: 'assigned_to',
    },
    {
        Header: 'Scouted',
        accessor: 'scouted_status',
        Cell: ({ value }) => {
            if (!value) {
                return null;
            }

            const Status = {
                Active: {
                    scheme: "red",
                    icon: RiRecordCircleLine,
                },
                Pending: {
                    scheme: "blue",
                    icon: TbCalendarQuestion,
                },
                Ended: {
                    scheme: "purple",
                    icon: FaCalendarCheck,
                },
            };

            return (
                <Tag color={Status[value]?.scheme} colorScheme={Status[value]?.scheme}>
                    <TagLeftIcon as={Status[value]?.icon} boxSize="0.8rem" />
                    <TagLabel>{value}</TagLabel>
                </Tag>
            )
        }
    },
    {
        Header: 'Emails Sent',
        accessor: 'email_sent',
    },
    
    {
        Header: 'Total View',
        accessor: 'total_views',
    },
    {
        Header: 'Contacted',
        accessor: 'contacted',
        Cell: ({ value }) => {
            if (value) {
                
                const localDate = parseISO(value);
                const formattedDate = format(localDate, 'MM-dd-yyyy hh:mm:ss a');
                return formattedDate;
            }
        }
    },
    {
        Header: 'Result',
        accessor: 'result',
    },
    {
        Header: 'Notes',
        accessor: 'notes',
    }

// Add more data objects as needed
];


